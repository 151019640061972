import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { updateUser } from '../firebase/firebase';
import { auth } from '../firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

const GenerateBook = () => {
  const [user] = useAuthState(auth);
  const { t } = useTranslation();

  const handleSendToProductReview = async () => {
    try {
      const { uid, email } = user;
      const data = {
        generate_book: Math.round(Date.now() / 1000),
      };
      await updateUser({ uid, data, email });
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <h2 className="text-center" style={{ maxWidth: 600, marginBottom: 40 }}>
        {t('dashboardScreen.youHaveAnsweredAllQuestions')}
      </h2>
      <Button
        className="button-update"
        style={{ backgroundColor: '#60A680', borderColor: '#60A680' }}
        onClick={handleSendToProductReview}
      >
        {t('dashboardScreen.generateMyBook')}
      </Button>
    </div>
  );
};

export default GenerateBook;
