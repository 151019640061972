import ProductImage from '../assets/images/subscription_product.png';
import PhotoEnhancerImage from '../assets/images/photo_enhancer_product.png';
import { formatDate } from '../utils/common';
import { useState } from 'react';
import { SubscriptionCancelModal } from './SubscriptionCancelModal';
import { useTranslation } from 'react-i18next';

export const SubscriptionCard = ({
  data,
  setReload,
  lifetimeAccessPurchased,
}) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const isPhotoEnhancer = data.product.key.includes('photo-enhancer');
  const isLifetime = lifetimeAccessPurchased && !isPhotoEnhancer;
  const photoEnhancerDuration = data.product.plan.key.includes('unlimited')
    ? t('common.unlimited')
    : '50';
  const closestDate = formatDate(data.next_payment_at);
  const isActive = data.status === 'active' || isLifetime;
  const statusText = isActive ? t('common.active') : t('common.canceled');
  const message = isActive
    ? t('profileScreen.subscriptionActive')
    : t('profileScreen.accessEnd');

  const hideWithReload = () => {
    setShowModal(false);
    setReload(true);
  };

  return (
    <div
      style={{
        backgroundColor: '#FFF3CC',
        border: '1px solid #E8DAAB',
        marginBottom: '8px',
        padding: '20px',
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '16px',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <div style={{ display: 'flex', gap: '16px' }}>
          <img
            src={isPhotoEnhancer ? PhotoEnhancerImage : ProductImage}
            alt="Product image"
            style={{ width: 88 }}
            className="d-none d-lg-block"
          />
          <div>
            <p style={{ fontWeight: 700 }}>
              {isLifetime && `Memowrite ${t('profileScreen.accessTwo')}`}
              {isPhotoEnhancer && `Photo Enhancer - ${photoEnhancerDuration}`}
              {!isLifetime &&
                !isPhotoEnhancer &&
                `Memowrite ${data?.product?.plan?.duration}-${t('common.month')} ${t('common.access')}`}
            </p>
            <p style={{ margin: 0 }}>
              {isLifetime ? t('profileScreen.accessValidity') : message}{' '}
              <span style={{ fontWeight: 700 }}>
                {isLifetime ? t('profileScreen.access') : closestDate}
              </span>
            </p>
          </div>
        </div>
        <span
          style={{
            backgroundColor: isActive ? '#30A766' : '#FF5222',
            borderRadius: '4px',
            fontWeight: 600,
            color: '#FFF',
            padding: '2px 8px',
          }}
        >
          {statusText}
        </span>
      </div>
      {!isLifetime && (
        <div
          style={{
            display: 'inline-block',
            backgroundColor: '#FFB42233',
            borderRadius: '4px',
            color: '#755006',
            padding: '8px 12px',
            cursor: 'pointer',
            marginTop: '24px',
          }}
          onClick={() => setShowModal(true)}
        >
          {t('profileScreen.manageSubscription')}
        </div>
      )}
      <SubscriptionCancelModal
        visible={showModal}
        data={data}
        onHide={() => setShowModal(false)}
        onHideWithReload={hideWithReload}
        isPhotoEnhancer={isPhotoEnhancer}
      />
    </div>
  );
};
