import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import { auth, updateUser } from '../firebase/firebase';
import { useAuthState, useSignOut } from 'react-firebase-hooks/auth';
import { useTranslation } from 'react-i18next';
import LOGO from '../assets/memowrite_logo.svg';
import UserImage from '../assets/images/user.png';
import ExitIcon from '../assets/icons/exit.svg';
import BookIcon from '../assets/icons/book.svg';
import SettingsIcon from '../assets/icons/settings.svg';
import CartIcon from '../assets/icons/cart.svg';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserData } from '../hooks/useUserData';

const locales = {
  en: { title: '🇺🇸 English' },
  es: { title: '🇪🇦 Español' },
  fr: { title: '🇫🇷 Francais' },
  de: { title: '🇩🇪 Deutsch' },
  it: { title: '🇮🇹 Italiano' },
};

const Navigation = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [signOut] = useSignOut(auth);
  const location = useLocation();
  const { userData } = useUserData();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const lang = queryParams.get('lang');

    if (lang === 'es' && lang !== user?.language) {
      i18n.changeLanguage(lang);
    } else if (lang === 'fr' && lang !== user?.language) {
      i18n.changeLanguage(lang);
    } else if (lang === 'de' && lang !== user?.language) {
      i18n.changeLanguage(lang);
    } else if (lang === 'it' && lang !== user?.language) {
      i18n.changeLanguage(lang);
    }
  }, [location.search]);

  const admins = [
    'aurimas.paliukaitis@kilo.health',
    'support@getmemowrite.com',
    'karol.majevski@kilo.health',
    'andrii.bondarenko@kilo.health',
  ];
  const isAdmin = admins.includes(user?.email);

  const handleLogout = () => {
    navigate('/');
    signOut();
  };

  const handleChangeLanguage = async (language) => {
    i18n.changeLanguage(language);
    await updateUser({
      uid: user?.uid,
      data: { language },
      email: user?.email,
    });
  };

  return (
    <div className="main-navigation">
      <Navbar collapseOnSelect expand="lg" className="main-navbar">
        <Navbar.Brand href="/" style={{ padding: '0 16px' }}>
          <img src={LOGO} alt="memowrite logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-between"
          style={{
            width: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <NavDropdown
              title={locales[i18n?.resolvedLanguage]?.title}
              id="basic-nav-dropdown"
              style={{
                margin: '24px 16px 12px',
              }}
            >
              {Object.keys(locales).map((locale) => (
                <NavDropdown.Item
                  key={locales[locale]?.title}
                  onClick={() => handleChangeLanguage(locale)}
                >
                  {locales[locale]?.title}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
            <Nav
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
                marginTop: '16px',
                width: '100%',
              }}
            >
              <Nav.Link
                style={{
                  backgroundColor:
                    location.pathname === '/' ? '#FFE693' : 'transparent',
                  color: '#182230',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  padding: '10px 15px',
                }}
                href="/"
              >
                <img src={BookIcon} alt="menu" />
                <p style={{ margin: 0 }}>{t('common.dashboard')}</p>
              </Nav.Link>
              {userData?.code && (
                <Nav.Link
                  style={{
                    backgroundColor:
                      location.pathname === '/shop' ? '#FFE693' : 'transparent',
                    color: '#182230',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    padding: '10px 15px',
                  }}
                  href="/shop"
                >
                  <img src={CartIcon} alt="menu" />
                  <p style={{ margin: 0 }}>{t('common.shop')}</p>
                </Nav.Link>
              )}
              <Nav.Link
                style={{
                  backgroundColor:
                    location.pathname === '/profile'
                      ? '#FFE693'
                      : 'transparent',
                  color: '#182230',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  padding: '10px 15px',
                }}
                href="/profile"
              >
                <img src={SettingsIcon} alt="menu" />
                <p style={{ margin: 0 }}>{t('common.profile')}</p>
              </Nav.Link>
              {isAdmin && (
                <Nav.Link
                  style={{
                    backgroundColor:
                      location.pathname === '/admin'
                        ? '#FFE693'
                        : 'transparent',
                    color: '#182230',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    padding: '10px 15px',
                  }}
                  href="/admin"
                >
                  <img src={SettingsIcon} alt="menu" />
                  <p style={{ margin: 0 }}>{t('common.admin')}</p>
                </Nav.Link>
              )}
            </Nav>
            <div className="navbar-logout">
              <img
                style={{ width: '40px', height: '40px' }}
                src={UserImage}
                alt="user"
              />
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}
              >
                <p
                  style={{
                    margin: 0,
                    width: '110px',
                    overflowX: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: 600,
                    fontSize: '14px',
                  }}
                >
                  {userData?.name}
                </p>
                <p
                  style={{
                    margin: 0,
                    width: '110px',
                    overflowX: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '14px',
                    color: '#666600',
                  }}
                >
                  {user?.email}
                </p>
              </div>
              <div onClick={handleLogout} style={{ cursor: 'pointer' }}>
                <img
                  style={{ width: '15px', height: '15px' }}
                  src={ExitIcon}
                  alt="sign out"
                />
              </div>
            </div>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Navigation;
