import { useState } from 'react';
import axios from 'axios';
import { PIC_WISH_API_KEY } from '../constants/common';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../firebase/firebase';
import { generateUniqueId } from '../utils/common';

export const usePicWishApi = () => {
  const [picWishLoading, setPicWishLoading] = useState(false);
  const [picWishError, setPicWishError] = useState(null);
  const [enhancedImageUrl, setEnhancedImageUrl] = useState(null);
  const [enhancedImage, setEnhancedImage] = useState(null);

  const applyPhotoEnhancer = async (imageUrl) => {
    try {
      setPicWishLoading(true);
      setPicWishError(null);
      setEnhancedImageUrl(null);
      setEnhancedImage(null);

      const response = await axios.post(
        'https://techhk.aoscdn.com/api/tasks/visual/scale',
        {
          sync: '1',
          image_url: imageUrl,
          type: 'face',
          scale_factor: 1,
        },
        {
          headers: {
            'X-API-KEY': PIC_WISH_API_KEY,
          },
        }
      );
      const responseData = response.data;
      if (responseData && responseData.data) {
        const { image, progress } = responseData.data;

        if (progress < 100) {
          throw new Error('Error enhancing image');
        }

        // Fetch image as binary data
        const binaryFile = await axios.get(image, {
          responseType: 'arraybuffer', // Ensures binary data
        });
        const binaryData = new Uint8Array(binaryFile.data);
        // Store image in Firebase
        const filePath = `${Date.now()}/${generateUniqueId()}`;
        const storageRef = ref(storage, filePath);
        const file = await uploadBytes(storageRef, binaryData);
        const fileUrl = await getDownloadURL(file.ref);
        setEnhancedImage(file);
        setEnhancedImageUrl(fileUrl);
      } else {
        throw new Error('Error enhancing image');
      }
    } catch (error) {
      console.error(error);
      setPicWishError('Something went wrong. Please try again');
    } finally {
      setPicWishLoading(false);
    }
  };

  return {
    applyPhotoEnhancer,
    picWishLoading,
    picWishError,
    enhancedImage,
    setEnhancedImage,
    enhancedImageUrl,
    setEnhancedImageUrl,
  };
};
