import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { doc, collection, query, where, deleteDoc } from 'firebase/firestore';
import { firestore, updateUser } from '../firebase/firebase';
import { useCollection } from 'react-firebase-hooks/firestore';
import Accordion from 'react-bootstrap/Accordion';
import { FaCircleCheck, FaCircle } from 'react-icons/fa6';
import { IoWarning } from 'react-icons/io5';
import { formatNumberDeci } from '../utils/helpers';
import InfoModal from './InfoModal';
import { updateValueInNestedObjects } from '../utils/helpers';
import ImageFromStorage from './ImageFromStorage';
import { FaTrashAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import AddPhotos from './AddPhotos';
import { SKIP_ANSWER_VALUE, SKIPPED_ANSWER_VALUE } from '../constants/common';
import ConfirmModal from './ConfirmModal';
import ErrorToast from './ErrorToast';
import { useUserData } from '../hooks/useUserData';

const BookReviewModal = ({ user, visible, onClose, isAdmin }) => {
  const [editableChapter, setEditableChapter] = useState(null);
  const [chapterCustomTitle, setChapterCustomTitle] = useState(null);
  const [chapterCustomText, setChapterCustomText] = useState(null);
  const [cautionModal, setCautionModal] = useState(false);
  const [deletePhotoId, setDeletePhotoId] = useState(false);
  const [confirmPrintModal, setConfirmPrintModal] = useState(false);
  const [showDeleteChapterModal, setShowDeleteChapterModal] = useState(false);
  const [activeKey, setActiveKey] = useState(null);
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [error, setError] = useState(null);

  const { t } = useTranslation();
  const { userData } = useUserData(user);

  const [questionsDataRaw] = useCollection(collection(firestore, 'questions'), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  const emailRepliesQuery = query(
    collection(firestore, 'email-replies'),
    user?.email && where('sender', '==', user?.email)
  );
  const [emailReplies] = useCollection(emailRepliesQuery, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const questionsData = questionsDataRaw?.docs
    ?.map((item) => ({
      context: t(`question_${item?.id}.context`),
      question: t(`question_${item?.id}.question`),
      questionAsTitle: t(`question_${item?.id}.questionAsTitle`),
      tag: item?.data()?.tag,
      id: Number(item?.id),
    }))
    ?.sort((a, b) => a?.id - b?.id);

  const bookDataForAccordion = Object.values(
    userData?.book_qa_data || {}
  ).filter((q) => q.text !== SKIPPED_ANSWER_VALUE);
  const allQuestionsVerified = Object.values(
    userData?.book_qa_data || {}
  ).every((item) => item?.verified);

  const userPhotos = emailReplies?.docs
    ?.map((doc) => {
      const data = doc.data();
      const questionId = Number(data?.questionId);
      const id = doc?.id;
      const photos = data?.attachments?.map(
        (attachmentData) => attachmentData?.name
      );

      return {
        questionId,
        photos,
        id,
      };
    })
    ?.filter((i) => i?.photos);

  const stripClassesAndStyles = (htmlString) => {
    const tempElement = document.createElement('div');
    const htmlTagPattern = /<(?!\/?(p|b)\s?)[^>]*>/g;
    const emojiPattern =
      /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/gu;
    tempElement.innerHTML = htmlString
      .replace(htmlTagPattern, '')
      .replace(emojiPattern, '');

    // Set empty string for class and style attributes for all elements
    const allElements = tempElement.querySelectorAll('*');
    allElements.forEach((element) => {
      element.className = '';
      element.style.cssText = '';
    });

    return tempElement.innerHTML;
  };

  const handleVerify = (id) => {
    const newVal = {
      ...(userData?.book_qa_data || {}),
      [id]: {
        ...(userData?.book_qa_data?.[id] || {}),
        verified: true,
      },
    };

    updateUser({
      uid: user?.uid,
      data: {
        book_qa_data: newVal,
      },
      email: user?.email,
    });
    setChapterCustomText(null);
    setEditableChapter(null);
  };

  const handleSave = (id) => {
    const newVal = {
      ...(userData?.book_qa_data || {}),
      [id]: {
        ...(userData?.book_qa_data?.[id] || {}),
        customTitle: chapterCustomTitle
          ? stripClassesAndStyles(chapterCustomTitle)
          : null,
        text: chapterCustomText
          ? stripClassesAndStyles(chapterCustomText)
          : userData?.book_qa_data?.[id]?.text,
      },
    };

    updateUser({
      uid: user?.uid,
      data: {
        book_qa_data: newVal,
      },
      email: user?.email,
    });
    setChapterCustomText(null);
    setEditableChapter(null);
  };

  const handleVerifyAll = async () => {
    const verifiedBookQAData = updateValueInNestedObjects(
      userData?.book_qa_data,
      'verified',
      true
    );
    try {
      setError(null);
      updateUser({
        uid: user?.uid,
        data: {
          book_qa_data: verifiedBookQAData,
        },
        email: user?.email,
      });
    } catch (error) {
      setError('Error updating document. Please try again.');
      console.error('Error updating document: ', error);
    } finally {
      setChapterCustomText(null);
      setEditableChapter(null);
    }
  };

  const handleSendToPrint = async () => {
    try {
      setError(null);
      const data = {
        ready_for_print_book: Math.round(Date.now() / 1000),
      };
      await updateUser({ uid: user?.uid, data, email: user?.email });
    } catch (error) {
      setError('Error updating document. Please try again.');
      console.error('Error updating document: ', error);
    } finally {
      onClose();
    }
  };

  const handleDeletePhoto = async () => {
    try {
      setError(null);
      const deleteDocRef = doc(firestore, 'email-replies', deletePhotoId);

      await deleteDoc(deleteDocRef);
    } catch (error) {
      setError('Error updating document. Please try again.');
      console.error('Error updating document: ', error);
    } finally {
      setDeletePhotoId(false);
    }
  };

  const handleChapterDeletion = async () => {
    try {
      setError(null);
      const newVal = {
        ...(userData?.book_qa_data || {}),
        [selectedQuestionId]: {
          ...(userData?.book_qa_data?.[selectedQuestionId] || {}),
          text: SKIPPED_ANSWER_VALUE,
        },
      };

      await updateUser({
        uid: user?.uid,
        data: {
          book_qa_data: newVal,
        },
        email: user?.email,
      });
    } catch (error) {
      setError('Error updating document. Please try again.');
      console.error('Error updating document: ', error);
    } finally {
      setShowDeleteChapterModal(false);
    }
  };

  const renderImages = (questionId) => {
    return userPhotos
      ?.filter((i) => i?.questionId === questionId)
      ?.map((ii) => {
        if (ii?.photos?.length > 0) {
          return ii?.photos?.map(
            (photoUrl) =>
              photoUrl && (
                <div
                  key={photoUrl}
                  style={{
                    marginRight: 16,
                    marginBottom: 16,
                    position: 'relative',
                    borderStyle: 'solid',
                    borderColor: 'grey',
                    borderWidth: 1,
                    padding: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <FaTrashAlt
                    color="black"
                    size={16}
                    onClick={() => setDeletePhotoId(ii?.id)}
                    style={{ marginBottom: 4, cursor: 'pointer' }}
                  />

                  <ImageFromStorage
                    link={photoUrl}
                    height={90}
                    objectFit="contain"
                  />
                </div>
              )
          );
        }
        return null;
      });
  };

  const handleCollapseAll = () => {
    setActiveKey(null);
  };

  return (
    <>
      <Modal size="lg" fullscreen show={visible} onHide={onClose}>
        <Modal.Header closeButton>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontSize: 40, fontWeight: 700, marginBottom: 4 }}>
              {t('dashboardScreen.reviewBook')}
            </p>
            <p>{t('dashboardScreen.checkAndVerify')}</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Accordion
            activeKey={activeKey}
            onSelect={(key) => setActiveKey(key)}
          >
            {bookDataForAccordion?.map((item, index) => (
              <Accordion.Item key={item.questionId} eventKey={item.questionId}>
                <Accordion.Header>
                  {item?.verified ? (
                    <FaCircleCheck color="#60A680" />
                  ) : (
                    <FaCircle color="silver" />
                  )}
                  <span style={{ marginLeft: 8 }}>
                    Chapter {formatNumberDeci(index + 1)}.{' '}
                    {item?.customTitle ||
                      questionsData?.[item.questionId - 1]?.questionAsTitle}
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  <p style={{ fontWeight: 600 }}>
                    {t('dashboardScreen.chapterTitle')}
                  </p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        item?.customTitle ||
                        questionsData?.[item.questionId - 1]?.questionAsTitle,
                    }}
                    contentEditable={editableChapter === item.questionId}
                    onInput={(event) => {
                      setChapterCustomTitle(event.target.innerHTML);
                    }}
                    className={
                      editableChapter === item.questionId
                        ? 'content-editable'
                        : ''
                    }
                    style={{ marginBottom: 20 }}
                  />
                  <p style={{ fontWeight: 600 }}>
                    {t('dashboardScreen.chapterText')}
                  </p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        item?.text === SKIP_ANSWER_VALUE
                          ? t('dashboardScreen.youDidNotAnswerThisQuestion')
                          : item?.text,
                    }}
                    contentEditable={editableChapter === item.questionId}
                    onInput={(event) => {
                      setChapterCustomText(event.target.innerHTML);
                    }}
                    className={
                      editableChapter === item.questionId
                        ? 'content-editable'
                        : ''
                    }
                    style={{ marginBottom: 20 }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                    }}
                  >
                    {renderImages(item?.questionId)}
                    <div style={{ marginBottom: 20 }}>
                      <AddPhotos
                        questionId={item.questionId}
                        user={user}
                        isAdmin={isAdmin}
                      />
                    </div>
                  </div>
                  {(!item?.verified || isAdmin) && (
                    <>
                      {editableChapter === item.questionId ? (
                        <Button
                          onClick={() => {
                            handleSave(item.questionId);
                          }}
                          className="button-update"
                          variant="dark"
                          style={{
                            marginRight: 8,
                          }}
                        >
                          {t('common.save')}
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            handleVerify(item.questionId);
                            handleCollapseAll();
                          }}
                          className="button-update"
                          style={{
                            backgroundColor: '#60A680',
                            borderColor: '#60A680',
                            marginRight: 8,
                          }}
                        >
                          {t('common.verify')}
                        </Button>
                      )}
                      <Button
                        onClick={() => {
                          editableChapter === null &&
                            !isAdmin &&
                            setCautionModal(true);
                          setEditableChapter(
                            editableChapter === item.questionId
                              ? null
                              : item.questionId
                          );
                        }}
                        className="button-update"
                        variant="outline-dark"
                      >
                        {editableChapter === item.questionId
                          ? t('common.cancel')
                          : t('common.edit')}
                      </Button>
                    </>
                  )}
                  <div style={{ marginTop: 8 }}>
                    <Button
                      className="button-update"
                      onClick={() => {
                        setSelectedQuestionId(item.questionId);
                        setShowDeleteChapterModal(true);
                      }}
                      style={{
                        backgroundColor: '#dc3545',
                        borderColor: '#dc3545',
                      }}
                    >
                      {t('dashboardScreen.deleteChapter')}
                    </Button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="button-update"
            variant="outline-dark"
            onClick={onClose}
          >
            {t('common.close')}
          </Button>
          <Button
            className="button-update"
            variant="dark"
            onClick={handleVerifyAll}
          >
            {t('dashboardScreen.verifyAll')}
          </Button>
          <Button
            disabled={!allQuestionsVerified}
            className="button-update"
            style={{ backgroundColor: '#60A680', borderColor: '#60A680' }}
            onClick={() => setConfirmPrintModal(true)}
          >
            {t('dashboardScreen.sendToPrint')}
          </Button>
        </Modal.Footer>
      </Modal>
      <InfoModal
        visible={cautionModal}
        onHide={() => setCautionModal(false)}
        title={t('dashboardScreen.caution')}
        text={t('dashboardScreen.editThisTextAtYourRisk')}
        icon={<IoWarning size={26} style={{ marginRight: 4 }} color="orange" />}
      />
      <ConfirmModal
        visible={deletePhotoId}
        onHide={() => setDeletePhotoId(false)}
        title={t('dashboardScreen.areYouSure')}
        text={t('dashboardScreen.youWillDeleteThisPhoto')}
        icon={<IoWarning size={26} style={{ marginRight: 4 }} color="orange" />}
        onConfirm={handleDeletePhoto}
      />
      <ConfirmModal
        visible={showDeleteChapterModal}
        onHide={() => setShowDeleteChapterModal(false)}
        title={t('dashboardScreen.areYouSure')}
        text={t('dashboardScreen.youWillDeleteThisChapter')}
        icon={<IoWarning size={26} style={{ marginRight: 4 }} color="orange" />}
        onConfirm={handleChapterDeletion}
      />
      <ConfirmModal
        visible={confirmPrintModal}
        onHide={() => setConfirmPrintModal(false)}
        title={t('dashboardScreen.confirmBookContent')}
        text={t('dashboardScreen.youAreResonsibleForBookContent')}
        onConfirm={handleSendToPrint}
      />
      {error && <ErrorToast errorMessage={error} />}
    </>
  );
};

export default BookReviewModal;
