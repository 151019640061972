export const SKIP_ANSWER_VALUE = 'Skip';
export const SKIPPED_ANSWER_VALUE = 'Skipped';
export const ANSWER_MINIMAL_LENGTH = 10;
export const PIC_WISH_API_KEY = 'wxpj83jq79lfzysyz';
export const SUPPORT_EMAIL = 'support@getmemowrite.com';
export const RND_API_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://rnd-prod.kilohealthservices.com/api/'
    : 'https://rnd-sandbox.kilohealthservices.com/api/';
export const RND_API_TOKEN =
  process.env.NODE_ENV === 'production'
    ? 'b37cb2a1-14a2-409f-bb2c-4d15dc561b8f'
    : 'aa4f71ed-8eac-44ef-afe9-784bbac9bccb';
