import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { collection, query, where, getDocs, or } from 'firebase/firestore';
import { useState } from 'react';
import { firestore } from '../firebase/firebase';
import { format } from 'date-fns';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { FaSearch } from 'react-icons/fa';
import ErrorToast from './ErrorToast';

const AnswersTable = () => {
  const [searchEmail, setSearchEmail] = useState('');
  const [repliesLoading, setRepliesLoading] = useState(false);
  const [replies, setReplies] = useState(null);
  const [filter, setFilter] = useState(false);
  const [error, setError] = useState(null);

  const fetchRepliesData = async (f) => {
    try {
      setError(null);
      setRepliesLoading(true);

      const filterFunction = () => {
        if (searchEmail) {
          return where('sender', '==', searchEmail);
        }
        if (f === 'finished_email_users') {
          return where('questionId', '==', 50);
        }
        if (f === 'custom') {
          return or(
            where('questionId', '==', '50'),
            where('questionId', '==', 50)
          );
        }
        return null;
      };

      const emailRepliesQuery = query(
        collection(firestore, 'email-replies'),
        filterFunction()
      );
      const querySnapshot = await getDocs(emailRepliesQuery);
      const repliesForTable = [];

      querySnapshot?.forEach((doc) => {
        const data = doc.data();
        const date = format(data?.timestamp * 1000, 'yyy MM dd HH:mm');
        const email = data?.sender;
        const questionId = data?.questionId;
        const source = data?.subject ? 'email' : 'app';

        repliesForTable.push({
          timestamp: data?.timestamp,
          date,
          questionId,
          email,
          source,
        });
      });

      if (searchEmail) {
        setReplies(repliesForTable.sort((a, b) => b?.timestamp - a?.timestamp));
      } else {
        const checkUsers = repliesForTable?.map((user) => user?.email);
        const existingUsers = await fetchUsersData(checkUsers);

        function findMissingItems(fullArray, partialArray) {
          // Use filter to get items from fullArray that are not in partialArray
          const missingItems = fullArray.filter(
            (item) => !partialArray.includes(item)
          );
          return missingItems;
        }

        setReplies(
          findMissingItems(checkUsers, existingUsers)?.map((i) => ({
            email: i,
          }))
        );
      }
    } catch (e) {
      setError('Error fetching data. Please try again.');
      console.error(e);
    } finally {
      setRepliesLoading(false);
    }
  };

  const fetchUsersData = async (checkUsers) => {
    try {
      setError(null);
      const filterFunction = () => {
        if (checkUsers) {
          return where('email', 'in', checkUsers);
        }
      };

      const usersQuery = query(
        collection(firestore, 'users'),
        filterFunction()
      );
      const querySnapshot = await getDocs(usersQuery);
      const repliesForTable = [];

      querySnapshot?.forEach((doc) => {
        const data = doc.data();

        repliesForTable.push(data?.email);
      });

      return repliesForTable;
    } catch (e) {
      setError('Error fetching data. Please try again.');
      console.error(e);
    }
  };

  const handleFilter = (f) => {
    setFilter(f);
    fetchRepliesData(f);
  };

  return (
    <>
      <Row style={{ marginTop: 20 }}>
        <Col sm={6}>
          <h4>Answers</h4>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="User email"
              onChange={(e) => setSearchEmail(e.target.value)}
            />
            <Button variant="dark" onClick={fetchRepliesData}>
              Search
            </Button>
          </InputGroup>
        </Col>
        <Col className="pt-1">
          <span style={{ marginRight: 16 }}>Filters:</span>
          <Button
            size="sm"
            variant={
              filter === 'finished_email_users' ? 'dark' : 'outline-dark'
            }
            onClick={() => handleFilter('finished_email_users')}
            style={{ marginRight: 8 }}
          >
            Recently finished with email
          </Button>
        </Col>
      </Row>
      <Row className="mt-2">
        {repliesLoading ? (
          <Col className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" />
          </Col>
        ) : replies ? (
          <Col>
            <Table>
              <thead>
                <tr>
                  <th
                    className="text-light"
                    style={{
                      borderRightColor: 'white',
                      borderRightWidth: '2px',
                      backgroundColor: '#112423',
                    }}
                  >
                    <span style={{ marginLeft: 8 }}>Date</span>
                  </th>
                  <th
                    className="text-light"
                    style={{
                      borderRightColor: 'white',
                      borderRightWidth: '2px',
                      backgroundColor: '#112423',
                    }}
                  >
                    <span>Question</span>
                  </th>
                  <th
                    className="text-light"
                    style={{
                      borderRightColor: 'white',
                      borderRightWidth: '2px',
                      backgroundColor: '#112423',
                    }}
                  >
                    <span>Client</span>
                  </th>
                  <th
                    className="text-light"
                    style={{ backgroundColor: '#112423' }}
                  >
                    <span>Source</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {replies?.map((answer) => {
                  return (
                    <tr key={answer?.questionId}>
                      <td className="pt-3 pb-3 bg-body-tertiary">
                        <span
                          style={{
                            marginLeft: 8,
                          }}
                        >
                          {answer?.date}
                        </span>
                      </td>
                      <td className="pt-3 pb-3 bg-body-tertiary">
                        <span>{answer?.questionId}</span>
                      </td>
                      <td className="pt-3 pb-3 bg-body-tertiary">
                        <span>{answer?.email}</span>
                      </td>
                      <td className="pt-3 pb-3 bg-body-tertiary">
                        <span>{answer?.source}</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FaSearch size={20} style={{ marginRight: 4 }} />
            <span>Try search</span>
          </div>
        )}
      </Row>
      {error && <ErrorToast errorMessage={error} />}
    </>
  );
};

export default AnswersTable;
