import { useState, useRef } from 'react';
import { useDownloadURL } from 'react-firebase-hooks/storage';
import { ref as storageRef } from 'firebase/storage';
import { storage } from '../firebase/firebase';
import { useEffect } from 'react';
import EXIF from 'exif-js';
import { FaRotateRight } from 'react-icons/fa6';
import Button from 'react-bootstrap/Button';

const ImageFromStorage = ({
  link,
  height,
  width,
  fullWidth,
  objectFit,
  style,
  editable,
  isAdmin,
  ...rest
}) => {
  const [heightForFullWidth, setHeightForFullWidth] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [rotation, setRotation] = useState(1); // 1 = 0°, 3 = 180°, 6 = 90°, 8 = 270°

  const [downloadUrl, loading, error] = useDownloadURL(
    storageRef(storage, link)
  );
  const imgRef = useRef(null);
  const canvasRef = useRef(null);

  function adjustImageOrientation(img, rotation, callback) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if ([6, 8].includes(rotation)) {
      canvas.width = img.height;
      canvas.height = img.width;
    } else {
      canvas.width = img.width;
      canvas.height = img.height;
    }

    switch (rotation) {
      case 3: // 180°
        ctx.transform(-1, 0, 0, -1, img.width, img.height);
        break;
      case 6: // 90°
        ctx.translate(img.height, 0);
        ctx.transform(0, 1, -1, 0, 0, 0);
        break;
      case 8: // 270°
        ctx.translate(0, img.width);
        ctx.transform(0, -1, 1, 0, 0, 0);
        break;
      default: // 0°
        ctx.transform(1, 0, 0, 1, 0, 0);
    }

    ctx.drawImage(img, 0, 0);
    callback(canvas.toDataURL('image/jpeg'));
  }

  useEffect(() => {
    if (downloadUrl && imgRef?.current && isAdmin) {
      fetch(downloadUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const fileReader = new FileReader();
          fileReader.onload = function (e) {
            const img = new Image();
            img.onload = () => {
              EXIF.getData(img, function () {
                adjustImageOrientation(img, rotation, (dataUrl) => {
                  setImageSrc(dataUrl);
                });
              });
            };
            img.crossOrigin = 'Anonymous';
            img.src = e.target.result;
          };
          fileReader.readAsDataURL(blob);
        })
        .catch((error) =>
          console.error('Error fetching proxied image:', error)
        );
    }
  }, [downloadUrl, imgRef?.current, rotation, isAdmin]);

  useEffect(() => {
    if (fullWidth && imgRef?.current?.height) {
      setHeightForFullWidth(imgRef?.current?.height);
    }
  }, [fullWidth, imgRef?.current?.height]);

  const handleChangeOrientation = () => {
    setRotation((prev) => {
      const rotationStates = [1, 6, 3, 8]; // 0°, 90°, 180°, 270°
      const currentIndex = rotationStates.indexOf(prev);
      return rotationStates[(currentIndex + 1) % 4];
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
      <img
        ref={imgRef}
        src={imageSrc || downloadUrl}
        style={{
          objectFit: objectFit || 'contain',
          height: fullWidth ? heightForFullWidth : height,
          width: fullWidth ? '100%' : width,
          ...style,
        }}
        {...rest}
      />
      {editable && (
        <Button
          variant="danger"
          onClick={handleChangeOrientation}
          style={{
            position: 'absolute',
            top: 64,
            right: 64,
            cursor: 'pointer',
          }}
        >
          <FaRotateRight size={24} />
        </Button>
      )}
    </div>
  );
};

export default ImageFromStorage;
