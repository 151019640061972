import Modal from 'react-bootstrap/Modal';

const InfoModal = ({ visible, onHide, title, text, icon }) => {
  return (
    <Modal show={visible} onHide={onHide} centered>
      <Modal.Header closeButton style={{ border: 'none' }} />
      <Modal.Body>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 12,
          }}
        >
          {icon}
          {title && (
            <span style={{ fontSize: 20, fontWeight: 700 }}>{title}</span>
          )}
        </div>
        {text && <p>{text}</p>}
      </Modal.Body>
    </Modal>
  );
};

export default InfoModal;
