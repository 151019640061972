import Button from 'react-bootstrap/Button';
import { addDays, format } from 'date-fns';
import { useTranslation } from 'react-i18next';

const BookShipped = ({ userData }) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        className="text-center"
      >
        <h2>{t('dashboardScreen.yourBookIsOnItsWay')}</h2>
        {userData?.ship_book && (
          <p>
            {t('dashboardScreen.weHaveShippedYourBook')}{' '}
            {format(addDays(userData?.ship_book * 1000, 6), 'LLL dd')} -{' '}
            {format(addDays(userData?.ship_book * 1000, 8), 'LLL dd')}.
          </p>
        )}
        <Button variant="dark" className="button-update">
          <a
            href={userData?.tracking_link}
            target="_blank"
            rel="noreferrer"
            style={{ color: 'white', textDecoration: 'none' }}
          >
            {t('dashboardScreen.trackMyOrder')}
          </a>
        </Button>
      </div>
    </>
  );
};

export default BookShipped;
