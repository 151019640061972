import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoginForm from '../components/LoginForm';
import ResetPassword from '../components/ResetPassword';
import NavigationLogin from '../components/NavigationLogin';
import Footer from '../components/Footer';

function LoginPage() {
  const { t } = useTranslation();

  return (
    <div
      className="d-flex flex-column"
      style={{ backgroundColor: '#FFF3CC', minHeight: '100vh' }}
    >
      <Container className="flex-grow-1">
        <NavigationLogin />
        <Container style={{ paddingTop: '10%' }}>
          <Row className="justify-content-center align-items-center">
            <Col md="5" className="align-items-left">
              <h2 className="mb-4">{t('loginScreen.login')}</h2>
              <LoginForm />
              <ResetPassword />
            </Col>
          </Row>
        </Container>
      </Container>
      <Footer />
    </div>
  );
}

export default LoginPage;
