import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import { updateUser } from '../firebase/firebase';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const PrintModal = ({ visible, onClose, uid, email, language }) => {
  const [bookAmount, setBookAmount] = useState(0);
  const [coverImage, setCoverImage] = useState('');
  const [startDate, setStartDate] = useState(new Date());

  const handleSubmit = async () => {
    try {
      const url =
        'https://memowrite.app.n8n.cloud/webhook/c1dac4a1-e572-4888-a091-781c905c1618';
      const dataToSend = {
        email,
        book_amount: bookAmount,
        cover_image: coverImage,
        language,
      };

      await fetch(url, {
        method: 'POST',
        body: JSON.stringify(dataToSend),
      });

      await updateUser({
        uid,
        data: {
          book_amount: bookAmount,
          cover_image: coverImage,
          print_book: Math.round(startDate.getTime()) / 1000,
        },
        email,
      });
    } catch (e) {
      console.error(e);
    } finally {
      onClose();
    }
  };

  return (
    <Modal show={visible} onHide={onClose} centered>
      <Modal.Header closeButton style={{ border: 'none' }}>
        <Modal.Title>Book printing info</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>Book amount</Form.Label>
        <InputGroup>
          <Form.Control
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            value={bookAmount}
            onChange={(e) => setBookAmount(e.target.value)}
            type="number"
          />
        </InputGroup>
        <Form.Label className="mt-4">Cover image link</Form.Label>
        <InputGroup>
          <Form.Control
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            value={coverImage}
            onChange={(e) => setCoverImage(e.target.value)}
          />
        </InputGroup>
        <Col className="mt-4">
          <Form.Label>Print date</Form.Label>
          <InputGroup>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </InputGroup>
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={!bookAmount || !coverImage || !startDate}
          variant="dark"
          className="button-update"
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <Button
          variant="outline-dark"
          className="button-update"
          onClick={onClose}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PrintModal;
