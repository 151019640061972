import { useState, useEffect } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useAuthState, useSignOut } from 'react-firebase-hooks/auth';
import { auth, updateUser } from '../firebase/firebase';
import ChangePassword from '../components/ChangePassword';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import ErrorToast from '../components/ErrorToast';
import { useUserData } from '../hooks/useUserData';
import { Subscriptions } from '../components/Subscriptions';

function ProfilePage() {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openAccountTab, setOpenAccountTab] = useState(true);

  const [user] = useAuthState(auth);
  const { t } = useTranslation();
  const { userData } = useUserData();

  useEffect(() => {
    if (userData?.name) {
      setName(userData?.name);
    }
  }, [userData?.name]);

  const handleUpdateUser = async (data) => {
    setLoading(true);

    try {
      const { uid, email } = user;
      setError(null);
      await updateUser({ uid, data, email });
    } catch (error) {
      setError('Error updating document. Please try again.');
      console.error('Error updating document: ', error);
    } finally {
      setTimeout(() => setLoading(false), 1000);
    }
  };

  return (
    <div style={{ marginTop: '40px' }}>
      <h4 style={{ fontSize: '24px', fontWeight: 600, marginBottom: '24px' }}>
        {t('common.profile')}
      </h4>
      <div style={{ display: 'flex', gap: '16px' }}>
        <p
          onClick={() => setOpenAccountTab(true)}
          style={{
            cursor: 'pointer',
            fontSize: '14px',
            fontWeight: 600,
            paddingBottom: '4px',
            borderBottom: openAccountTab ? '2px solid #000' : '',
          }}
        >
          {t('profileScreen.accountDetails')}
        </p>
        <p
          onClick={() => setOpenAccountTab(false)}
          style={{
            cursor: 'pointer',
            fontSize: '14px',
            fontWeight: 600,
            paddingBottom: '4px',
            borderBottom: openAccountTab ? '' : '2px solid #000',
          }}
        >
          {t('profileScreen.changePassword')}
        </p>
      </div>
      <div
        style={{
          backgroundColor: '#FFF',
          boxShadow: '0px 4px 18px 0px #00000014',
          padding: '24px',
          borderRadius: '4px',
        }}
      >
        {openAccountTab ? (
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          >
            <div style={{ maxWidth: '400px' }}>
              <Form.Label>{t('profileScreen.fullName')}</Form.Label>
              <InputGroup>
                <Form.Control
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <Button
                  variant="dark"
                  onClick={() => handleUpdateUser({ name })}
                >
                  {loading && <Spinner size="sm" style={{ marginRight: 8 }} />}{' '}
                  {t('common.save')}
                </Button>
              </InputGroup>
            </div>
            <div style={{ maxWidth: '400px' }}>
              <Form.Label>{t('common.email')}</Form.Label>
              <InputGroup>
                <Form.Control
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  value={user?.email}
                  disabled
                />
              </InputGroup>
            </div>
            <Subscriptions />
          </div>
        ) : (
          <ChangePassword user={user} />
        )}
      </div>
      {error && <ErrorToast errorMessage={error} />}
    </div>
  );
}

export default ProfilePage;
