import { useState } from 'react';
import { useSendPasswordResetEmail } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/firebase';
import { useTranslation } from 'react-i18next';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const ResetPassword = () => {
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [email, setEmail] = useState(null);
  const [inputError, setErrorInput] = useState(null);

  const { t } = useTranslation();

  const [sendPasswordResetEmail, sending, error] =
    useSendPasswordResetEmail(auth);
  const handleEmailChange = (e) => {
    const { value } = e.target;
    setErrorInput(null);
    setEmail(value);
  };

  const handleSend = async () => {
    try {
      if (!email || email === null) {
        setErrorInput(t('loginScreen.emailRequired'));
        return;
      }

      await sendPasswordResetEmail(email);
    } catch (error) {
      setErrorInput(t('loginScreen.somethingWentWrong'));
    } finally {
      setResetPasswordModal(false);
    }
  };

  return (
    <>
      <Button
        className="mt-1"
        variant="link"
        style={{ paddingLeft: 0, color: 'black' }}
        onClick={() => setResetPasswordModal(true)}
      >
        <span>{t('loginScreen.forgotPassword')}</span>
      </Button>
      <Modal
        show={resetPasswordModal}
        onHide={() => setResetPasswordModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          style={{ border: 'none' }}
          onHide={() => setResetPasswordModal(false)}
        >
          <Modal.Title>{t('loginScreen.resetPassword')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-2" controlId="formBasicEmail">
            <Form.Label className="mb-3">
              {t('loginScreen.resetPasswordHeadline')}
            </Form.Label>
            <p className="mb-1">• {t('loginScreen.resetPasswordP1')}</p>
            <p className="mb-4">• {t('loginScreen.resetPasswordP2')}</p>
            <Form.Control
              type="email"
              aria-label="Default"
              aria-describedby="inputGroup-sizing-default"
              value={email}
              onChange={handleEmailChange}
            />
          </Form.Group>
          <span className="text-danger">{inputError}</span>
        </Modal.Body>
        <Modal.Footer style={{ border: 'none' }}>
          <Button variant="dark" onClick={handleSend}>
            {t('common.send')}
          </Button>
          <Button
            variant="outline-dark"
            onClick={() => setResetPasswordModal(false)}
          >
            {t('common.cancel')}
          </Button>
        </Modal.Footer>
      </Modal>{' '}
    </>
  );
};

export default ResetPassword;
