import COVER_01 from '../assets/covers/cover_01.png';
import COVER_02 from '../assets/covers/cover_02.png';
import COVER_03 from '../assets/covers/cover_03.png';
import COVER_04 from '../assets/covers/cover_04.png';
import COVER_05 from '../assets/covers/cover_05.png';
import COVER_06 from '../assets/covers/cover_06.png';
import COVER_07 from '../assets/covers/cover_07.png';
import COVER_08 from '../assets/covers/cover_08.png';
import COVER_09 from '../assets/covers/cover_09.png';
import COVER_10 from '../assets/covers/cover_10.png';
import COVER_11 from '../assets/covers/cover_11.png';
import COVER_12 from '../assets/covers/cover_12.png';
import COVER_WITHOUT_NAME_01 from '../assets/covers/cover_without_name_01.png';
import COVER_WITHOUT_NAME_02 from '../assets/covers/cover_without_name_02.png';
import COVER_WITHOUT_NAME_03 from '../assets/covers/cover_without_name_03.png';
import COVER_WITHOUT_NAME_04 from '../assets/covers/cover_without_name_04.png';
import COVER_WITHOUT_NAME_05 from '../assets/covers/cover_without_name_05.png';
import COVER_WITHOUT_NAME_06 from '../assets/covers/cover_without_name_06.png';
import COVER_WITHOUT_NAME_07 from '../assets/covers/cover_without_name_07.png';
import COVER_WITHOUT_NAME_08 from '../assets/covers/cover_without_name_08.png';
import COVER_WITHOUT_NAME_09 from '../assets/covers/cover_without_name_09.png';
import COVER_WITHOUT_NAME_10 from '../assets/covers/cover_without_name_10.png';
import COVER_WITHOUT_NAME_11 from '../assets/covers/cover_without_name_11.png';
import COVER_WITHOUT_NAME_12 from '../assets/covers/cover_without_name_12.png';

export const COVERS = [
  COVER_01,
  COVER_02,
  COVER_03,
  COVER_04,
  COVER_05,
  COVER_06,
  COVER_07,
  COVER_08,
  COVER_09,
  COVER_10,
  COVER_11,
  COVER_12,
];

export const COVERS_WITHOUT_NAMES = [
  COVER_WITHOUT_NAME_01,
  COVER_WITHOUT_NAME_02,
  COVER_WITHOUT_NAME_03,
  COVER_WITHOUT_NAME_04,
  COVER_WITHOUT_NAME_05,
  COVER_WITHOUT_NAME_06,
  COVER_WITHOUT_NAME_07,
  COVER_WITHOUT_NAME_08,
  COVER_WITHOUT_NAME_09,
  COVER_WITHOUT_NAME_10,
  COVER_WITHOUT_NAME_11,
  COVER_WITHOUT_NAME_12,
];

export const PRODUCTION_STEPS = [
  'generate_book',
  'review_book',
  'ready_for_print_book',
  'print_book',
  'ship_book',
  'done',
];
