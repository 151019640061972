import { useState, useEffect } from 'react';
import { useUserData } from '../hooks/useUserData';
import { SUPPORT_EMAIL } from '../constants/common';
import { RndApi } from '../api/rndApi';
import Spinner from 'react-bootstrap/Spinner';
import { SubscriptionCard } from './SubscriptionCard';
import { useTranslation } from 'react-i18next';

export const Subscriptions = () => {
  const { userData } = useUserData();
  const { t } = useTranslation();

  const [subscriptions, setSubscriptions] = useState([]);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [lifetimeAccessPurchased, setLifetimeAccessPurchased] = useState(false);

  useEffect(() => {
    (async () => {
      if (!userData || (!reload && !loading)) return;

      if (userData && !userData?.code) {
        setLoading(false);
        setShowError(true);

        return;
      }

      try {
        setShowError(false);
        const response = await RndApi.fetchClientSubscriptions(userData?.code);
        const leadData = await RndApi.fetchLead(userData?.code);
        setSubscriptions(response.subscriptions);
        leadData.client.orders.forEach((order) => {
          if (order.items.find((i) => i.product.key.includes('lifetime'))) {
            setLifetimeAccessPurchased(true);
          }
        });
      } catch {
        setShowError(true);
      } finally {
        setLoading(false);
        setReload(false);
      }
    })();
  }, [loading, userData, reload]);

  return (
    <div style={{ marginTop: '40px' }}>
      <p style={{ marginBottom: '16px', fontSize: '12', fontWeight: 500 }}>
        {t('common.subscriptions')}
      </p>
      {loading ? (
        <div style={{ marginTop: '16px' }}>
          <Spinner animation="border" />
        </div>
      ) : (
        <div>
          {subscriptions.map((data) => (
            <SubscriptionCard
              key={data.id}
              data={data}
              setReload={setReload}
              lifetimeAccessPurchased={lifetimeAccessPurchased}
            />
          ))}
          {showError && (
            <p style={{ color: 'red', marginTop: '16px' }}>
              {t('profileScreen.subscriptionsError')}
              <br />
              <a href="mailto:{{SUPPORT_EMAIL}}">{SUPPORT_EMAIL}</a>
            </p>
          )}
        </div>
      )}
    </div>
  );
};
