import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import BookReviewModal from './BookReviewModal';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/firebase';
import { useTranslation } from 'react-i18next';

const BookReview = () => {
  const [modal, setModal] = useState(false);

  const [user] = useAuthState(auth);
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          className="text-center"
        >
          <h2>{t('dashboardScreen.yourBookIsReadyForReview')}</h2>
          <p>{t('dashboardScreen.takeALookAtItBefore')}</p>
        </div>
        <Button
          variant="dark"
          className="button-update mt-2"
          onClick={() => setModal(true)}
        >
          {t('common.review')}
        </Button>
      </div>
      <BookReviewModal
        user={user}
        visible={modal}
        onClose={() => setModal(false)}
      />
    </>
  );
};

export default BookReview;
