import Navigation from './Navigation';

export const Layout = ({ children }) => {
  return (
    <div className="layout">
      <Navigation />
      <div className="main-container">{children}</div>
    </div>
  );
};
