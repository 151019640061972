import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { auth, updateUser } from '../firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useTranslation } from 'react-i18next';
import LOGO from '../assets/memowrite_logo.svg';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const locales = {
  en: { title: '🇺🇸 English' },
  es: { title: '🇪🇦 Español' },
  fr: { title: '🇫🇷 Francais' },
  de: { title: '🇩🇪 Deutsch' },
  it: { title: '🇮🇹 Italiano' },
};

const NavigationLogin = () => {
  const { i18n } = useTranslation();
  const [user] = useAuthState(auth);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const lang = queryParams.get('lang');

    if (lang === 'es' && lang !== user?.language) {
      i18n.changeLanguage(lang);
    } else if (lang === 'fr' && lang !== user?.language) {
      i18n.changeLanguage(lang);
    } else if (lang === 'de' && lang !== user?.language) {
      i18n.changeLanguage(lang);
    } else if (lang === 'it' && lang !== user?.language) {
      i18n.changeLanguage(lang);
    }
  }, [location.search]);

  const handleChangeLanguage = async (language) => {
    i18n.changeLanguage(language);
    await updateUser({
      uid: user?.uid,
      data: { language },
      email: user?.email,
    });
  };

  return (
    <Navbar collapseOnSelect expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img src={LOGO} alt="memowrite logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <NavDropdown
          title={locales[i18n?.resolvedLanguage || 'en']?.title}
          id="basic-nav-dropdown"
          className="mt-2"
        >
          {Object.keys(locales).map((locale) => (
            <NavDropdown.Item
              key={locales[locale]?.title}
              onClick={() => handleChangeLanguage(locale)}
            >
              {locales[locale]?.title}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      </Container>
    </Navbar>
  );
};

export default NavigationLogin;
