import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { auth, updateUser } from '../firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AddPhoto from './AddPhoto';
import CustomCover from './CustomCover';
import { useTranslation } from 'react-i18next';
import ErrorToast from './ErrorToast';
import { useUserData } from '../hooks/useUserData';

const AddCover = () => {
  const [coverColor, setCoverColor] = useState(0);
  const [coverType, setCoverType] = useState(0);
  const [error, setError] = useState(null);

  const { t } = useTranslation();
  const [user] = useAuthState(auth);
  const { userData } = useUserData();

  const handleSave = async () => {
    const cover = Number(coverType === 0 ? coverColor + 6 : coverColor) + 1;

    try {
      setError(null);
      await updateUser({
        uid: user?.uid,
        data: { cover },
        email: user?.email,
      });
    } catch (error) {
      setError('Error updating document. Please try again.');
      console.error('Error updating document: ', error);
    }
  };

  const coverColors = [
    '#437462',
    '#C5B9AA',
    '#BFA1A1',
    '#305C90',
    '#DADADA',
    '#373638',
  ];
  const coverTypes = ['Regular', 'With photo'];

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h2 className="text-center">
          {t('dashboardScreen.chooseDesignCover')}
        </h2>
        <p className="mb-4 text-center">
          {t('dashboardScreen.youCanChooseBetweenDifferentColors')}
        </p>
        <Row style={{ width: '100%' }}>
          <Col xs={12} md={6} className="order-2 order-md-1">
            <p className="mt-4" style={{ marginBottom: 8 }}>
              {t('dashboardScreen.selectColor')}
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {coverColors.map((item, idx) => (
                <div
                  key={`cover_${idx}`}
                  onClick={() => setCoverColor(idx)}
                  style={{
                    cursor: 'pointer',
                    borderStyle: 'solid',
                    borderColor: idx === coverColor ? 'grey' : 'transparent',
                    borderWidth: 2,
                    marginRight: 8,
                    width: 40,
                    height: 40,
                    borderRadius: 20,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: item,
                      borderRadius: 14,
                      height: 28,
                      width: 28,
                    }}
                  ></div>
                </div>
              ))}
            </div>
            <p style={{ marginTop: 24, marginBottom: 8 }}>
              {t('dashboardScreen.selectType')}
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {coverTypes.map((item, idx) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginRight: 16,
                  }}
                >
                  <div
                    key={`cover_${idx}`}
                    onClick={() => setCoverType(idx)}
                    style={{
                      cursor: 'pointer',
                      borderStyle: 'solid',
                      borderColor: 'grey',
                      borderWidth: 2,
                      marginRight: 8,
                      width: 40,
                      height: 40,
                      borderRadius: 20,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      style={{
                        backgroundColor:
                          idx === coverType ? 'grey' : 'transparent',
                        borderRadius: 14,
                        height: 28,
                        width: 28,
                      }}
                    ></div>
                  </div>
                  <span>{item}</span>
                </div>
              ))}
            </div>
            <p
              style={{
                marginTop: 24,
                marginBottom: 8,
                color: coverType === 0 ? 'grey' : 'black',
              }}
            >
              {t('dashboardScreen.uploadCoverPhoto')}
            </p>
            <AddPhoto disabled={coverType === 0} />
            <Button
              variant="dark"
              className="button-update"
              onClick={handleSave}
              style={{ marginTop: 24 }}
              disabled={coverType === 1 && !userData?.cover_photo}
            >
              {t('dashboardScreen.confirmBookCover')}
            </Button>
          </Col>
          <Col
            xs={12}
            md={6}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            className="order-1 order-md-2"
          >
            <CustomCover
              cover={(coverType === 0 ? coverColor + 6 : coverColor) + 1}
              width={320}
              coverPhoto={userData?.cover_photo}
              withPhoto={coverType === 1}
              name={userData?.name}
              title={t('book.myLifeStory')}
            />
          </Col>
        </Row>
      </div>
      {error && <ErrorToast errorMessage={error} />}
    </>
  );
};

export default AddCover;
