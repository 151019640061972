import { useState } from 'react';
import { useUpdatePassword } from 'react-firebase-hooks/auth';
import { reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { auth } from '../firebase/firebase';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { ChangePasswordSuccessModal } from './ChangePasswordSuccessModal';

const ChangePassword = ({ user }) => {
  const [currentPass, setCurrentPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [newPassRetype, setNewPassRetype] = useState('');
  const [inputError, setInputError] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(null);

  const { t } = useTranslation();
  const [updatePassword] = useUpdatePassword(auth);

  const handleCurrentPassChange = (e) => {
    const { value } = e.target;
    setInputError(null);
    setCurrentPass(value);
  };

  const handleNewPassChange = (e) => {
    const { value } = e.target;
    setInputError(null);
    setNewPass(value);
  };

  const handleNewPassRetypePassChange = (e) => {
    const { value } = e.target;
    setInputError(null);
    setNewPassRetype(value);
  };

  const handleSave = async () => {
    try {
      if (!currentPass || !newPass || !newPassRetype) {
        setInputError(t('profileScreen.fillAllFields'));
        return;
      }
      if (newPass?.length < 7 || currentPass?.length < 7) {
        setInputError(t('profileScreen.passwordAtLeast8'));
        return;
      }
      if (newPass !== newPassRetype) {
        setInputError(t('profileScreen.passwordsDoNotMatch'));
        return;
      }

      const credential = EmailAuthProvider.credential(user?.email, currentPass);
      await reauthenticateWithCredential(user, credential);
      const success = await updatePassword(newPass);

      if (success) {
        setShowSuccessModal(true);
      }
    } catch (e) {
      setInputError(t('profileScreen.somethingWentWrong'));
      console.error(e);
    }
  };

  return (
    <div style={{ color: '#000' }}>
      <p style={{ fontSize: '12px', fontWeight: 500, marginBottom: '8px' }}>
        {t('profileScreen.currentPassword')}
      </p>
      <Form.Control
        type="password"
        aria-label="Default"
        aria-describedby="inputGroup-sizing-default"
        value={currentPass}
        onChange={handleCurrentPassChange}
        placeholder={t('profileScreen.currentPassword')}
        style={{
          backgroundColor: '#0000000A',
          color: '#000',
        }}
      />
      <p
        style={{
          fontSize: '12px',
          fontWeight: 500,
          marginTop: '16px',
          marginBottom: '8px',
        }}
      >
        {t('profileScreen.newPassword')}
      </p>
      <Form.Control
        type="password"
        aria-label="Default"
        aria-describedby="inputGroup-sizing-default"
        value={newPass}
        onChange={handleNewPassChange}
        placeholder={t('profileScreen.newPassword')}
        style={{
          backgroundColor: '#0000000A',
          color: '#000',
        }}
      />
      <p
        style={{
          fontSize: '12px',
          fontWeight: 500,
          marginTop: '16px',
          marginBottom: '8px',
        }}
      >
        {t('profileScreen.retypeNewPassword')}
      </p>
      <Form.Control
        type="password"
        aria-label="Default"
        aria-describedby="inputGroup-sizing-default"
        value={newPassRetype}
        onChange={handleNewPassRetypePassChange}
        placeholder={t('profileScreen.retypeNewPassword')}
        style={{
          backgroundColor: '#0000000A',
          color: '#000',
        }}
      />
      <p className="text-danger">{inputError}</p>
      <div
        onClick={handleSave}
        style={{
          borderRadius: '4px',
          backgroundColor: '#000',
          display: 'inline-block',
          padding: '8px 12px',
          color: '#FFF',
          fontWeight: 600,
          cursor: 'pointer',
          marginTop: '24px',
        }}
      >
        {t('profileScreen.changePassword')}
      </div>
      <ChangePasswordSuccessModal
        visible={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
      />
    </div>
  );
};

export default ChangePassword;
