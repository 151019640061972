import Modal from 'react-bootstrap/Modal';
import CheckboxFilledInclined from '../assets/icons/checkbox_filled_inclined.svg';
import { useTranslation } from 'react-i18next';
import { RndApi } from '../api/rndApi';
import { useState } from 'react';

export const UpsellSuccessModal = ({
  visible,
  onHide,
  upsellPurchaseUrl,
  upsellImage,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handlePurchase = async () => {
    if (loading) return;

    try {
      setLoading(true);
      setError(false);
      await RndApi.buyOneClick(upsellPurchaseUrl);
      setSuccess(true);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={visible}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        {success || error ? (
          <div style={{ textAlign: 'center' }}>
            {!error && <img src={CheckboxFilledInclined} alt="success" />}
            <p style={{ fontSize: '24px', fontWeight: 600, marginTop: '16px' }}>
              {error ? (
                t('loginScreen.somethingWentWrong')
              ) : (
                <>
                  {t('shopScreen.success')}
                  <br />
                  {t('shopScreen.thankYou')}
                </>
              )}
            </p>
            <div style={{ textAlign: 'center' }}>
              <div
                onClick={onHide}
                className="button-black"
                style={{
                  display: 'inline-block',
                }}
              >
                {t('common.close')}
              </div>
            </div>
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <img
              src={upsellImage}
              alt="book"
              style={{ width: '130px', height: 'auto' }}
            />
            <p
              style={{
                fontSize: '16px',
                fontWeight: 500,
                marginTop: '16px',
                marginBottom: '16px',
              }}
            >
              {t('shopScreen.confirmPurchase')}
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              {!loading && (
                <div
                  onClick={onHide}
                  className="button-transparent"
                  style={{
                    display: 'inline-block',
                  }}
                >
                  {t('common.cancel')}
                </div>
              )}
              <div
                onClick={handlePurchase}
                className="button-black"
                style={{
                  display: 'inline-block',
                  cursor: loading ? 'initial' : 'pointer',
                  opacity: loading ? 0.5 : 1,
                }}
              >
                {t('common.confirm')}
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
