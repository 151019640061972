import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage, updateUser } from '../firebase/firebase';
import { useTranslation } from 'react-i18next';
import { useUserData } from '../hooks/useUserData';
import { generateUniqueId } from '../utils/common';
import { usePicWishApi } from '../hooks/usePicWishApi';
import Spinner from 'react-bootstrap/Spinner';

const AddPhotoModal = ({ visible, onHide, user, isAdmin }) => {
  const {
    applyPhotoEnhancer,
    enhancedImageUrl,
    enhancedImage,
    setEnhancedImageUrl,
    setEnhancedImage,
    picWishError,
    picWishLoading,
  } = usePicWishApi();
  const { userData } = useUserData(user);
  const { t } = useTranslation();

  const [userImage, setUserImage] = useState(null);
  const [uploadedFileUrl, setUploadedFileUrl] = useState(null);
  const [imageDescription, setImageDescription] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const questionId = visible;
  const showPhotoEnhancer = userData?.photo_enhancer || isAdmin;

  const resetDataAndClose = () => {
    setImageDescription(null);
    setEnhancedImage(null);
    setEnhancedImageUrl(null);
    setError(null);
    setUserImage(null);
    setUploadedFileUrl(null);
    onHide();
  };

  const handleFileUpload = async (e) => {
    try {
      setError(null);
      setLoading(true);
      setEnhancedImageUrl(null);
      setUserImage(null);

      const file = e.target.files[0];
      const photoPath = `${Date.now()}_${user?.email?.replace(
        /[^a-zA-Z0-9]+/g,
        '_'
      )}-${questionId}/${generateUniqueId()}_${file?.name}`;
      const photoRef = ref(storage, photoPath);
      const fileData = await uploadBytes(photoRef, file);
      setUserImage(fileData);
      const imageUrl = await getDownloadURL(fileData.ref);
      setUploadedFileUrl(imageUrl);
    } catch (error) {
      setError(t('dashboardScreen.errorAddingImage'));
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageDescription = (e) => {
    setImageDescription(e.target.value);
  };

  const handleSave = async (image) => {
    try {
      await updateUser({
        uid: user?.uid,
        data: {
          book_qa_photos: {
            ...(userData?.book_qa_photos || {}),
            [questionId]: [
              ...(userData?.book_qa_photos?.[questionId] || []),
              {
                path: image?.metadata?.fullPath,
                image_description: imageDescription,
              },
            ],
          },
        },
        email: user?.email,
      });
      resetDataAndClose();
    } catch {
      setError(t('dashboardScreen.errorAddingImage'));
    }
  };

  return (
    <>
      <Modal
        show={visible}
        onHide={resetDataAndClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          style={{ border: 'none' }}
          onHide={resetDataAndClose}
        >
          <p style={{ fontSize: 20, fontWeight: 700, marginBottom: 0 }}>
            {t('dashboardScreen.questionPhoto')} #{questionId}
          </p>
        </Modal.Header>
        <Modal.Body>
          <Form style={{ maxWidth: '600px', margin: '0 auto' }}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>{t('dashboardScreen.selectPhoto')}</Form.Label>
              <Form.Control
                type="file"
                accept=".png, .jpg, .jpeg"
                onChange={handleFileUpload}
              />
              <p style={{ fontSize: 12, color: 'grey', marginTop: 12 }}>
                {t('dashboardScreen.photoFileFormats')}
              </p>
            </Form.Group>
            <Form.Group className="mb-3" controlId="imageDescription">
              <Form.Label>{t('dashboardScreen.imageDescription')}</Form.Label>
              <Form.Control
                value={imageDescription}
                onChange={handleImageDescription}
                type="text"
              />
            </Form.Group>
          </Form>
          {loading ? (
            <div style={{ marginTop: '24px', textAlign: 'center' }}>
              <Spinner animation="border" />
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              {uploadedFileUrl && (
                <>
                  <img
                    src={uploadedFileUrl}
                    alt="your file"
                    style={{
                      maxWidth: '100%',
                    }}
                  />
                  {showPhotoEnhancer && (
                    <>
                      <p
                        style={{
                          fontFamily: 'EB Garamond sans-serif',
                          fontSize: '18px',
                          margin: '12px 0',
                          textAlign: 'left',
                        }}
                      >
                        {t('dashboardScreen.enhanceImageText')}
                      </p>
                      <Button
                        onClick={() => applyPhotoEnhancer(uploadedFileUrl)}
                        style={{
                          backgroundColor: '#4a8ad8',
                          borderColor: '#4a8ad8',
                        }}
                        className="button-update"
                        disabled={enhancedImageUrl}
                      >
                        {t('dashboardScreen.enhanceImageCta')}
                      </Button>
                    </>
                  )}
                </>
              )}
              {picWishLoading && (
                <div style={{ marginTop: '24px', textAlign: 'center' }}>
                  <Spinner animation="border" />
                </div>
              )}
              {enhancedImageUrl && (
                <>
                  <div style={{ textAlign: 'center' }}>
                    <img
                      src={enhancedImageUrl}
                      alt="enhanced image"
                      style={{
                        marginTop: '24px',
                        maxWidth: '100%',
                      }}
                    />
                  </div>
                  <p
                    style={{
                      fontFamily: 'EB Garamond sans-serif',
                      fontSize: '18px',
                      margin: '12px 0',
                      textAlign: 'left',
                    }}
                  >
                    {t('dashboardScreen.selectImageMessage')}
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '16px',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: '#60A680',
                        borderColor: '#60A680',
                      }}
                      className="button-update"
                      onClick={() => handleSave(userImage)}
                    >
                      {t('dashboardScreen.saveMyImage')}
                    </Button>
                    <Button
                      style={{
                        backgroundColor: '#60A680',
                        borderColor: '#60A680',
                      }}
                      className="button-update"
                      onClick={() => handleSave(enhancedImage)}
                    >
                      {t('dashboardScreen.saveOtherImage')}
                    </Button>
                  </div>
                </>
              )}
            </div>
          )}
          {(error || picWishError) && (
            <p style={{ color: '#E13636', margin: '12px 0 0' }}>
              {t('profileScreen.somethingWentWrong')}
            </p>
          )}
        </Modal.Body>
        <Modal.Footer style={{ border: 'none' }}>
          {uploadedFileUrl && enhancedImageUrl ? (
            <></>
          ) : (
            <Button
              style={{ backgroundColor: '#60A680', borderColor: '#60A680' }}
              className="button-update"
              onClick={() => handleSave(userImage)}
            >
              {t('common.save')}
            </Button>
          )}
          <Button
            variant="outline-dark"
            className="button-update"
            onClick={resetDataAndClose}
          >
            {t('common.cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddPhotoModal;
