import { useState } from 'react';
import { auth, updateUser } from '../firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { FaRegImage } from 'react-icons/fa6';
import AddPhotoModal from './AddPhotoModal';
import ImageFromStorage from './ImageFromStorage';
import { FaTrashAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useUserData } from '../hooks/useUserData';

const AddPhotos = ({ questionId, user, isAdmin, style = {} }) => {
  const [addPhotoModal, setAddPhotoModal] = useState(false);

  const { t } = useTranslation();
  const [authUser] = useAuthState(auth);
  user = user || authUser;
  const { userData } = useUserData(user);

  const maxPhotosCount = 50;
  const uploadPhotosCount = Object.values(
    userData?.book_qa_photos || {}
  )?.length;
  const questionPhotos = userData?.book_qa_photos?.[questionId];

  const handleDeletePhoto = (deleteIdx) => {
    const updatedPhotos = questionPhotos?.filter(
      (item, idx) => idx !== deleteIdx
    );

    updateUser({
      uid: user?.uid,
      data: {
        book_qa_photos: {
          ...(userData?.book_qa_photos || {}),
          [questionId]: updatedPhotos,
        },
      },
      email: user?.email,
    });
  };

  return (
    <div style={style}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {questionPhotos?.map((item, idx) => {
          if (item?.path) {
            return (
              <div
                key={item?.path}
                style={{
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderColor: 'black',
                  padding: 4,
                  marginRight: 12,
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: 240,
                }}
              >
                <FaTrashAlt
                  style={{
                    marginBottom: 4,
                    cursor: 'pointer',
                    alignSelf: 'flex-end',
                  }}
                  onClick={() => handleDeletePhoto(idx)}
                />
                <div style={{ alignSelf: 'center' }}>
                  <ImageFromStorage link={item?.path} height={90} />
                </div>
                {item?.image_description && (
                  <p
                    style={{
                      textAlign: 'center',
                      fontSize: 10,
                      marginBottom: 0,
                      marginTop: 4,
                    }}
                  >
                    {item?.image_description}
                  </p>
                )}
              </div>
            );
          }
        })}
        {maxPhotosCount > uploadPhotosCount && (
          <div
            onClick={() => setAddPhotoModal(questionId)}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              borderStyle: 'dashed',
              borderColor: 'grey',
              marginTop: 12,
              marginRight: 12,
              width: 100,
              height: 100,
              cursor: 'pointer',
            }}
          >
            <FaRegImage size={24} color="grey" />
            <span style={{ fontSize: 14, marginTop: 6 }}>
              {t('dashboardScreen.addPhoto')}
            </span>
            <span
              style={{
                fontSize: 10,
                color:
                  uploadPhotosCount === maxPhotosCount - 1 ? 'orange' : 'black',
              }}
            >
              {uploadPhotosCount}/{maxPhotosCount}
            </span>
          </div>
        )}
      </div>
      <AddPhotoModal
        visible={addPhotoModal}
        onHide={() => {
          setAddPhotoModal(false);
        }}
        user={user}
        isAdmin={isAdmin}
      />
    </div>
  );
};

export default AddPhotos;
