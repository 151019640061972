import i18n from 'i18next';

const constructUserAnswers = ({ rawAnswers, userEmail }) =>
  rawAnswers?.docs
    ?.map((doc) => {
      const data = doc.data();
      const questionId = Number(data?.questionId);
      const id = doc?.id;
      const photos = data?.attachments?.map(
        (attachmentData) => attachmentData?.name
      );

      return {
        ...data,
        questionId,
        photos,
        id,
      };
    })
    ?.filter((item) => {
      return item?.sender === userEmail;
    });

const constructUserQuestions = ({
  rawQuestions,
  userAnswers,
  bookQaPhotos,
  language,
}) =>
  rawQuestions?.docs
    ?.map((q) => {
      const questionId = q?.id;
      const qAnswers = userAnswers?.filter(
        (a) => Number(a?.questionId) === Number(questionId)
      );
      const qPhotos = qAnswers
        ?.filter((a) => a?.photos)
        ?.map((a) => a?.photos)
        ?.flat()
        ?.map((p) => ({ path: p, image_description: null }));

      const newPhotos = bookQaPhotos?.[questionId]?.map((item) => item);

      return {
        context: i18n.t(`question_${questionId}.context`, { lng: language }),
        question: i18n.t(`question_${questionId}.question`, { lng: language }),
        questionAsTitle: i18n.t(`question_${questionId}.questionAsTitle`, {
          lng: language,
        }),
        questionId,
        answers: qAnswers,
        photos: [...(qPhotos || []), ...(newPhotos || [])],
      };
    })
    ?.sort((a, b) => {
      return a?.questionId - b?.questionId;
    });

export const constructUserQuestionsWithAnswers = ({
  questions,
  answers,
  userEmail,
  bookQaPhotos,
  language,
}) => {
  const userAnswers = constructUserAnswers({ rawAnswers: answers, userEmail });
  const userQuestions = constructUserQuestions({
    rawQuestions: questions,
    userAnswers,
    bookQaPhotos,
    language,
  });

  return userQuestions?.map((currentItem, index, array) => ({
    ...currentItem,
    available: true,
    previousAnswers: array[index - 1]?.answers || [],
  }));
};
