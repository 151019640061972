export function groupObjectsByProperty(arr, property) {
  return arr?.reduce((grouped, obj) => {
    const key = obj[property];

    if (!grouped[key]) {
      grouped[key] = [];
    }

    grouped[key].push(obj);

    return grouped;
  }, {});
}

export const formatNumberDeci = (id) => {
  if (id < 10) {
    return '0' + id;
  } else {
    return id;
  }
};

export function updateValueInNestedObjects(obj, keyToUpdate, newValue) {
  return Object.keys(obj).reduce((acc, nestedKey) => {
    if (typeof obj[nestedKey] === 'object') {
      const updatedObj = { ...obj[nestedKey] }; // Create a copy of the original nested object
      updatedObj[keyToUpdate] = newValue; // Update the specified key with the new value
      acc[nestedKey] = updatedObj; // Update the nested object within the accumulator
    } else {
      acc[nestedKey] = obj[nestedKey]; // If not an object, retain the original value
    }
    return acc;
  }, {});
}
