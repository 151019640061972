import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Suspense } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { auth } from './firebase/firebase';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import ProfilePage from './pages/ProfilePage';
import ShopPage from './pages/ShopPage';
import AdminPage from './pages/AdminPage';
import MouseflowScript from './components/MouseflowScript';
import { Layout } from './components/Layout';

function App() {
  const [user, loading] = useAuthState(auth);

  return (
    <Suspense fallback="...loading">
      <MouseflowScript />
      <Router>
        {loading ? (
          <Container
            style={{ marginTop: '25%' }}
            className="d-flex justify-content-center align-items-center"
          >
            <Spinner animation="border" />
          </Container>
        ) : user ? (
          <Layout>
            <Routes>
              <Route path="/" element={<DashboardPage />} />
              <Route path="/shop" element={<ShopPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/admin" element={<AdminPage />} />
            </Routes>
          </Layout>
        ) : (
          <LoginPage />
        )}
      </Router>
    </Suspense>
  );
}

export default App;
