import Button from 'react-bootstrap/Button';
import { auth } from '../firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import FIRST_UPSELL from '../assets/images/first_upsell.jpg';

const FirstUpsell = ({ onPress }) => {
  const [user] = useAuthState(auth);

  const handleBuy = () => {
    window.open(
      `https://shop.getmemowrite.com/cart/40534487072862:1?checkout[email]=${user?.email}`,
      '_blank',
      'noopener,noreferrer'
    );
    onPress();
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <h2 style={{ marginBottom: 28 }}>
        Exclusive Offer: Add +2 Books Now & Save Big!
      </h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
        }}
      >
        <img
          src={FIRST_UPSELL}
          alt="Memowrite Upsell Offer"
          style={{ width: 400, marginRight: 24 }}
          className="d-none d-lg-block"
        />
        <div>
          <p>
            <b>
              Congratulations on starting your memoir journey with Memowrite!
            </b>
          </p>
          <p>
            As you’ve embarked on this beautiful path, we have a one-time offer
            just for you.
          </p>
          <p>
            While 76% of our customers choose to buy 2 additional copies at full
            price after completing their memoir, we want to give you the chance
            to save now.
          </p>
          <p style={{ marginBottom: 32 }}>
            <b>
              Complete your memoir experience by securing +2 extra copies of
              your book at a special 50% discount.
            </b>
          </p>
          <img
            src={FIRST_UPSELL}
            alt="Memowrite Upsell Offer"
            style={{ width: '100%', marginBottom: 20 }}
            className="d-block d-lg-none"
          />
          <span style={{ textTransform: 'uppercase', fontSize: 12 }}>
            <b>Save 50% on 2 books COPIES now:</b>
          </span>
          <p style={{ fontSize: 32 }}>
            <b>
              <span style={{ textDecoration: 'line-through' }}>$120</span>{' '}
              $59.99
            </b>
          </p>
          <Button
            className="button-update mt-2 pt-3 pb-3"
            onClick={handleBuy}
            style={{
              width: '100%',
              backgroundColor: '#60A680',
              borderColor: '#60A680',
              color: 'white',
            }}
          >
            Pay now 59.99
          </Button>
          <Button
            variant="link"
            style={{
              color: 'black',
              textAlign: 'center',
              width: '100%',
            }}
            className="mt-2"
            onClick={onPress}
          >
            No im not interested in this offer
          </Button>
          <p className="mt-4">
            This is a unique opportunity to ensure that your loved ones can
            share in the legacy you’re creating — without the extra cost later
            on.
          </p>
          <p>
            <b>
              Remember, this exclusive half-price offer is only available right
              now, right after your first question is answered. It won't be
              available later, and it’s the perfect way to plan for those future
              gifts and keepsakes at half the cost.
            </b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FirstUpsell;
