import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AnswerItem from './AnswerItem';
import { useTranslation } from 'react-i18next';

const CurrentQuestion = ({ lastAvailableQuestion, onSuccess }) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col
        xs="12"
        md="6"
        style={{
          textAlign: 'center',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <h6 className="current-question">
          {t('dashboardScreen.currentQuestion')}
        </h6>
      </Col>
      <Col
        xs="12"
        md="6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <AnswerItem
          key="currentQuestionAnswerItem"
          data={lastAvailableQuestion}
          current={true}
          onSuccess={onSuccess}
          closeModalAfterSaving={true}
        />
      </Col>
    </Row>
  );
};

export default CurrentQuestion;
