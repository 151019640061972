import { useUserData } from '../hooks/useUserData';
import { RndApi } from '../api/rndApi';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import { UpsellSuccessModal } from '../components/UpsellSuccessModal';
import UpsellImage from '../assets/images/upsell_memory_recall.png';

function ShopPage() {
  const { t } = useTranslation();
  const { userData } = useUserData();

  const [upsellsLoading, setUpsellsLoading] = useState(true);
  const [upsells, setUpsells] = useState(null);
  const [upsellUrls, setUpsellUrls] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedUpsellKey, setSelectedUpsellKey] = useState(null);
  const [selectedUpsellImage, setSelectedUpsellImage] = useState(null);

  useEffect(() => {
    if (!userData || !userData?.code || !upsellsLoading) return;

    (async () => {
      try {
        const response = await RndApi.fetchUpsells(userData?.code);
        const data = response?.data.filter(
          (u) =>
            u.key.includes('upsell-additional-books') ||
            u.key === 'upsell-memory-recall'
        );
        data.sort((a, b) => a.prices[0].final_price - b.prices[0].final_price);
        setUpsells(data);
        setUpsellUrls(response?.upsell_urls);
      } catch (error) {
        console.error(error);
      } finally {
        setUpsellsLoading(false);
      }
    })();
  }, [userData, upsellsLoading]);

  const handleClick = (upsellKey, upsellImage) => {
    setSelectedUpsellKey(upsellKey);
    setShowModal(true);
    setSelectedUpsellImage(upsellImage);
  };

  return (
    <>
      <div style={{ marginTop: '40px' }}>
        <p style={{ marginBottom: '24px', fontSize: '24px', fontWeight: 600 }}>
          {t('common.shop')}
        </p>
        {upsellsLoading ? (
          <Spinner animation="border" />
        ) : (
          <div className="login-screen-container">
            {upsells.map((upsell) => {
              const isMemoryRecall = upsell.key === 'upsell-memory-recall';
              const quantity = upsell?.properties?.find(
                (p) => p.name === 'quantity'
              )?.value;
              const image = upsell?.properties?.find(
                (p) => p.name === 'image'
              )?.value;
              const initialPrice = upsell?.prices[0]?.price;
              const finalPrice = upsell?.prices[0]?.final_price;
              const discountPercents = (
                100 -
                (finalPrice / initialPrice) * 100
              ).toFixed(0);

              return (
                <div key={upsell.id} className="login-screen-card">
                  {isMemoryRecall ? (
                    <img
                      src={UpsellImage}
                      alt="book"
                      style={{ width: '130px', height: 'auto' }}
                    />
                  ) : (
                    <img
                      src={image}
                      alt="book"
                      style={{ width: '175px', height: 'auto' }}
                    />
                  )}
                  <p
                    style={{
                      marginBottom: 0,
                      fontSize: '16px',
                      fontWeight: 600,
                      textAlign: 'center',
                    }}
                  >
                    {isMemoryRecall ? (
                      t('shopScreen.memorySparkGuide')
                    ) : (
                      <>
                        {quantity} {t('shopScreen.additionalBooks')}
                      </>
                    )}
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      gap: '8px',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                    }}
                  >
                    <p
                      style={{
                        marginBottom: 0,
                        fontSize: '32px',
                        fontWeight: 600,
                      }}
                    >
                      ${finalPrice}
                    </p>
                    <p
                      style={{
                        marginBottom: 0,
                        fontSize: '12px',
                        color: '#FFF',
                        backgroundColor: '#C62828',
                        borderRadius: '2px',
                        fontWeight: 600,
                        padding: '2px 4px',
                      }}
                    >
                      {t('profileScreen.save')} {discountPercents}%
                    </p>
                  </div>
                  <p
                    style={{
                      marginBottom: 0,
                      fontSize: '14px',
                      textAlign: 'center',
                    }}
                  >
                    {t('shopScreen.regularPrice')}:{' '}
                    <span style={{ fontWeight: 600, color: '#C62828' }}>
                      ${initialPrice}
                    </span>
                  </p>
                  <div style={{ textAlign: 'center', marginTop: '16px' }}>
                    <div
                      onClick={() =>
                        handleClick(
                          upsell.key,
                          isMemoryRecall ? UpsellImage : image
                        )
                      }
                      className="button-black"
                      style={{
                        fontWeight: 500,
                        display: 'inline-block',
                        cursor: 'pointer',
                        opacity: 1,
                      }}
                    >
                      {t('shopScreen.orderNow')}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {selectedUpsellKey && (
        <UpsellSuccessModal
          onHide={() => setShowModal(false)}
          upsellPurchaseUrl={upsellUrls[selectedUpsellKey]}
          upsellImage={selectedUpsellImage}
          visible={showModal}
        />
      )}
    </>
  );
}

export default ShopPage;
