import { Button } from 'react-bootstrap';
import { SlLock } from 'react-icons/sl';
import { FaCheck } from 'react-icons/fa6';
import { formatNumberDeci } from '../utils/helpers';
import ImageFromStorage from './ImageFromStorage';
import { useTranslation } from 'react-i18next';

const QuestionTemplate = ({
  id,
  question,
  context,
  photos,
  onClick,
  disabled = false,
  answered = false,
  current,
}) => {
  const { t } = useTranslation();

  const showBubble = answered || disabled;

  const selectColor = () => {
    if (answered) {
      return '#60A680';
    }
    if (disabled) {
      return '#8D98A9';
    }

    return 'black';
  };

  const handleClick = () => {
    if (disabled) {
      return;
    }
    onClick();
  };

  return (
    <div
      onClick={handleClick}
      className="bg-white"
      style={{
        paddingTop: 30,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        borderRadius: 16,
        maxWidth: 400,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        marginBottom: 20,
        cursor: 'pointer',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: 60,
        }}
      >
        <h4
          style={{
            fontSize: 28,
            marginRight: 18,
            fontWeight: 500,
            marginBottom: 0,
            color: selectColor(),
          }}
        >
          {formatNumberDeci(id)}
        </h4>
        <p
          style={{
            fontSize: 16,
            fontWeight: 500,
            marginBottom: 0,
            color: selectColor(),
          }}
        >
          {question}
        </p>
      </div>
      <div
        style={{
          width: '100%',
          height: 1,
          backgroundColor: '#E8EDF1',
          marginTop: 20,
          marginBottom: 12,
        }}
      ></div>
      <p
        className="multiline-ellipsis"
        style={{
          fontSize: 14,
          marginBottom: 20,
          color: 'grey',
          height: 64,
          maxWidth: 360,
        }}
      >
        {context}
      </p>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        {photos?.map((photo, idx) => (
          <div
            key={`photo_${idx}_of_question_${id}`}
            style={{ borderRadius: 6, overflow: 'hidden', marginRight: 10 }}
          >
            <ImageFromStorage
              link={photo.path}
              height={50}
              width={50}
              objectFit="cover"
            />
          </div>
        ))}
      </div>
      {current && (
        <Button
          onClick={onClick}
          style={{
            backgroundColor: disabled ? '#8D98A9' : '#60A680',
            borderColor: disabled ? '#8D98A9' : '#60A680',
            borderRadius: 32,
            fontWeight: 600,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: -40,
            paddingTop: 10,
            paddingBottom: 10,
            alignSelf: 'center',
          }}
          disabled={disabled}
        >
          {t('dashboardScreen.answerQuestion')}
        </Button>
      )}
      {showBubble && (
        <div
          style={{
            position: 'absolute',
            top: -10,
            right: -10,
            width: 34,
            height: 34,
            borderRadius: 17,
            backgroundColor: answered ? '#60A680' : '#E58900',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {disabled ? (
            <SlLock size={16} color="white" />
          ) : (
            <FaCheck size={16} color="white" />
          )}
        </div>
      )}
    </div>
  );
};

export default QuestionTemplate;
