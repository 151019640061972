import Modal from 'react-bootstrap/Modal';
import { RND_API_TOKEN, RND_API_URL, SUPPORT_EMAIL } from '../constants/common';
import { useUserData } from '../hooks/useUserData';
import PhotoEnhancerImage from '../assets/images/photo_enhancer_product.png';
import ProductImage from '../assets/images/subscription_product.png';
import ProductLifetimeImage from '../assets/images/product_lifetime.png';
import { formatDate } from '../utils/common';
import { useEffect, useState } from 'react';
import CheckboxEmpty from '../assets/icons/checkbox_empty.svg';
import CheckboxFilled from '../assets/icons/checkbox_filled.svg';
import CheckboxOrange from '../assets/icons/checkbox_orange.svg';
import CheckboxFilledInclined from '../assets/icons/checkbox_filled_inclined.svg';
import { useTranslation } from 'react-i18next';
import { RndApi } from '../api/rndApi';

export const SubscriptionCancelModal = ({
  data,
  isPhotoEnhancer,
  visible,
  onHide,
  onHideWithReload,
}) => {
  const { userData } = useUserData();
  const { t } = useTranslation();

  const [showInfo, setShowInfo] = useState(data.status !== 'canceled');
  const [showForm, setShowForm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(data.status === 'canceled');
  const [showUpsell, setShowUpsell] = useState(false);
  const [reason, setReason] = useState(null);
  const [otherReason, setOtherReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingUpsells, setLoadingUpsells] = useState(true);
  const [upsellData, setUpsellData] = useState(true);
  const [upsellUrl, setUpsellUrl] = useState(null);
  const [showError, setShowError] = useState(false);
  const [statusChanged, setStatusChanged] = useState(false);
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [showPurchaseError, setShowPurchaseError] = useState(false);
  const [showPurchaseSuccess, setShowPurchaseSuccess] = useState(false);

  useEffect(() => {
    if (isPhotoEnhancer || !userData || !userData?.code || !loadingUpsells) {
      return;
    }

    (async () => {
      try {
        const response = await RndApi.fetchUpsells(userData.code);

        setUpsellData(
          response.data.find((u) => u.key === 'upsell-lifetime-product')
        );
        setUpsellUrl(response.upsell_urls['upsell-lifetime-product']);
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingUpsells(false);
      }
    })();
  }, [isPhotoEnhancer, loadingUpsells, userData]);

  const reasons = [
    {
      key: 'profileScreen.reasons.one',
      value: 'not_interested',
    },
    {
      key: 'profileScreen.reasons.two',
      value: 'financial_reasons',
    },
    {
      key: 'profileScreen.reasons.three',
      value: 'lack_of_time',
    },
    {
      key: 'profileScreen.reasons.four',
      value: 'unsatisfied',
    },
    {
      key: 'profileScreen.reasons.five',
      value: 'personal_reasons',
    },
    {
      key: 'profileScreen.reasons.six',
      value: 'already_completed',
    },
    {
      key: 'profileScreen.reasons.seven',
      value: 'other',
    },
  ];

  const photoEnhancerDuration = data.product.plan.key.includes('unlimited')
    ? t('common.unlimited')
    : '50';
  const closestDate = formatDate(data.next_payment_at);
  const disableSubmit = !reason || loading;

  const triggerCancelSubscriptionRequest = async () => {
    const url = `${RND_API_URL}payments/clients/${userData?.code}/subscriptions/${data.id}/cancel?api_token=${RND_API_TOKEN}`;
    const payload = {
      data: {
        cancellation_reason: otherReason ? `${reason}: ${otherReason}` : reason,
      },
    };

    await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
  };

  const handleCancelClick = () => {
    setShowInfo(false);

    if (isPhotoEnhancer || !upsellUrl) {
      setShowForm(true);

      return;
    }

    setShowUpsell(true);
  };

  const handlePurchase = async () => {
    if (purchaseLoading) return;

    try {
      setPurchaseLoading(true);
      setShowPurchaseError(false);
      setReason('lifetime_access_purchased');
      await RndApi.buyOneClick(upsellUrl);
      await triggerCancelSubscriptionRequest();
      setStatusChanged(true);
      setShowUpsell(false);
      setShowPurchaseSuccess(true);
    } catch {
      setShowPurchaseError(true);
    } finally {
      setPurchaseLoading(false);
      setReason(null);
    }
  };

  const handleContinueClick = () => {
    setShowUpsell(false);
    setShowForm(true);
  };

  const hideModal = () => {
    statusChanged ? onHideWithReload() : onHide();
    setStatusChanged(false);
  };

  const cancelSubscription = async () => {
    if (disableSubmit) return;

    try {
      setLoading(true);
      setShowError(false);
      await triggerCancelSubscriptionRequest();
      setShowForm(false);
      setShowSuccess(true);
      setStatusChanged(true);
    } catch {
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={visible}
      onHide={hideModal}
      size={showSuccess || showPurchaseSuccess ? 'md' : 'lg'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {!showSuccess && !showPurchaseSuccess && (
        <Modal.Header
          closeButton
          style={{ alignItems: 'center', border: 'none', padding: '32px' }}
          onHide={hideModal}
        >
          <p style={{ fontWeight: 600, fontSize: '24px', margin: 0 }}>
            {!showForm && !showUpsell && t('profileScreen.manageSubscription')}
            {showUpsell && t('profileScreen.specialOffer')}
            {showForm && t('profileScreen.cancelSubscription')}
          </p>
        </Modal.Header>
      )}
      <Modal.Body
        style={{
          padding: showSuccess || showPurchaseSuccess ? '40px' : '16px',
        }}
      >
        <div>
          {showInfo && (
            <>
              <div
                style={{
                  backgroundColor: '#FFF3CC',
                  border: '1px solid #E8DAAB',
                  padding: '16px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: '16px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: '16px',
                    }}
                  >
                    <img
                      src={isPhotoEnhancer ? PhotoEnhancerImage : ProductImage}
                      alt="Product image"
                      style={{ width: 88 }}
                      className="d-none d-lg-block"
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                      }}
                    >
                      <p style={{ margin: 0, fontWeight: 700 }}>
                        {isPhotoEnhancer
                          ? `Photo Enhancer - ${photoEnhancerDuration}`
                          : `Memowrite ${data?.product?.plan?.duration}-${t('common.month')} ${t('common.access')}`}
                      </p>
                      <p style={{ margin: 0 }}>
                        {t('profileScreen.nextBillingDate')}:{' '}
                        <span style={{ fontWeight: 700 }}>{closestDate}</span>
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      alignItems: 'flex-end',
                    }}
                  >
                    <p style={{ margin: 0, fontSize: '16px', fontWeight: 600 }}>
                      ${data.product.prices[0].final_price}
                    </p>
                    <p style={{ margin: 0, fontSize: '12px' }}>
                      {t('common.every')} {data.product.plan.duration}{' '}
                      {t('common.months')}
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  textAlign: 'right',
                  marginTop: '24px',
                  marginBottom: '24px',
                }}
              >
                <div
                  onClick={handleCancelClick}
                  className="button-black"
                  style={{
                    display: 'inline-block',
                  }}
                >
                  {t('profileScreen.cancelSubscription')}
                </div>
              </div>
            </>
          )}
          {showUpsell && (
            <div
              style={{
                backgroundColor: '#FFF3CC',
                border: '1px solid #E8DAAB',
                borderRadius: '8px',
                padding: '20px',
                color: '#000',
                marginBottom: '24px',
              }}
            >
              <p
                style={{
                  textAlign: 'center',
                  marginBottom: '24px',
                  fontSize: '24px',
                  fontWeight: 600,
                }}
              >
                {t('profileScreen.lifetimeTitle')}
              </p>
              <p
                style={{
                  textAlign: 'center',
                  marginBottom: '24px',
                  fontSize: '16px',
                }}
              >
                {t('profileScreen.lifetimeSubtitle')}
              </p>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '16px',
                  padding: '16px',
                  borderRadius: '4px',
                  border: '1px solid #E8DAAB',
                  backgroundColor: '#FFF',
                  marginBottom: '24px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '16px',
                  }}
                >
                  <img
                    src={ProductLifetimeImage}
                    alt="product"
                    style={{ width: '112px' }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                    }}
                  >
                    <p
                      style={{
                        marginBottom: 0,
                        fontWeight: 600,
                        fontSize: '16px',
                      }}
                    >
                      {t('profileScreen.cardTitle')}
                    </p>
                    <p style={{ marginBottom: 0, fontSize: '14px' }}>
                      {t('profileScreen.cardSubtitle')}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                      fontSize: '14px',
                      textDecoration: 'line-through',
                      color: '#00000099',
                    }}
                  >
                    ${upsellData.prices[0].price}
                  </p>
                  <p
                    style={{
                      marginBottom: '4px',
                      fontSize: '16px',
                      fontWeight: 600,
                      color: '#C62828',
                    }}
                  >
                    ${upsellData.prices[0].final_price}{' '}
                    {t('profileScreen.sale')}
                  </p>
                  <p
                    style={{
                      marginBottom: 0,
                      backgroundColor: '#000',
                      padding: '2px 4px',
                      borderRadius: '2px',
                      fontSize: '12px',
                      fontWeight: 600,
                      color: '#FFB422',
                    }}
                  >
                    🔥{t('profileScreen.save')}{' '}
                    {(
                      100 -
                      (upsellData.prices[0].final_price /
                        upsellData.prices[0].price) *
                        100
                    ).toFixed(0)}
                    %
                  </p>
                </div>
              </div>
              <p style={{ fontWeight: 600, fontSize: '14px' }}>
                {t('profileScreen.lifetimeFeaturesTitle')}
              </p>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  marginBottom: '12px',
                }}
              >
                <img
                  style={{ width: '20px', height: '20px' }}
                  src={CheckboxOrange}
                  alt="user"
                />
                <p
                  style={{ fontSize: '14px', fontWeight: 500, marginBottom: 0 }}
                >
                  {t('profileScreen.lifetimeFeatures.one')}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  marginBottom: '12px',
                }}
              >
                <img
                  style={{ width: '20px', height: '20px' }}
                  src={CheckboxOrange}
                  alt="user"
                />
                <p
                  style={{ fontSize: '14px', fontWeight: 500, marginBottom: 0 }}
                >
                  {t('profileScreen.lifetimeFeatures.two')}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  marginBottom: '12px',
                }}
              >
                <img
                  style={{ width: '20px', height: '20px' }}
                  src={CheckboxOrange}
                  alt="user"
                />
                <p
                  style={{ fontSize: '14px', fontWeight: 500, marginBottom: 0 }}
                >
                  {t('profileScreen.lifetimeFeatures.three')}
                </p>
              </div>
              <div style={{ display: 'flex', gap: '12px', marginTop: '32px' }}>
                <div
                  onClick={handleContinueClick}
                  className="button-transparent"
                  style={{
                    width: '50%',
                  }}
                >
                  {t('profileScreen.continue')}
                </div>
                <div
                  onClick={handlePurchase}
                  className="button-black"
                  style={{
                    fontWeight: 500,
                    width: '50%',
                    cursor: purchaseLoading ? 'initial' : 'pointer',
                    opacity: purchaseLoading ? 0.5 : 1,
                  }}
                >
                  {t('profileScreen.upgrade')}
                </div>
              </div>
              {showPurchaseError && (
                <p style={{ color: '#E13636', margin: '12px 0 0' }}>
                  {t('profileScreen.somethingWentWrong')}
                </p>
              )}
            </div>
          )}
          {showForm && (
            <div
              style={{
                border: '1px solid #0000001A',
                borderRadius: '8px',
                backgroundColor: '#FAFAFA',
                padding: '20px',
                color: '#000',
                marginBottom: '24px',
              }}
            >
              <p
                style={{
                  marginBottom: '32px',
                  fontWeight: 500,
                }}
              >
                {t('profileScreen.infoMessage')}
              </p>
              {reasons.map(({ key, value }) => (
                <div
                  key={key}
                  onClick={() => setReason(value)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '16px',
                    cursor: 'pointer',
                    marginBottom: '16px',
                  }}
                >
                  <div style={{ display: 'inline-block' }}>
                    <img
                      src={reason === value ? CheckboxFilled : CheckboxEmpty}
                      alt="checkbox"
                    />
                  </div>
                  <p style={{ margin: 0 }}>{t(key)}</p>
                </div>
              ))}
              {reason === 'other' && (
                <div style={{ marginTop: '20px' }}>
                  <p style={{ fontWeight: 600 }}>
                    {t('profileScreen.reasonTitle')}
                  </p>
                  <input
                    type="text"
                    value={otherReason}
                    onChange={(e) => setOtherReason(e.target.value)}
                    placeholder={t('profileScreen.reasonTitle')}
                    style={{
                      backgroundColor: '#0000000A',
                      border: '1px solid #0000001A',
                      color: '#000000',
                      padding: '12px 16px',
                      width: '100%',
                    }}
                  />
                </div>
              )}
              <div
                onClick={cancelSubscription}
                className="button-black"
                style={{
                  cursor: disableSubmit ? 'initial' : 'pointer',
                  marginTop: '32px',
                  opacity: disableSubmit ? 0.5 : 1,
                }}
              >
                {t('profileScreen.cancelSubscription')}
              </div>
              {showError && (
                <p
                  style={{
                    color: 'red',
                    marginTop: '8px',
                    marginBottom: '8px',
                  }}
                >
                  {t('profileScreen.errorMessage')}
                  <br />
                  <a href="mailto:{{SUPPORT_EMAIL}}">{SUPPORT_EMAIL}</a>
                </p>
              )}
            </div>
          )}
          {showSuccess && (
            <div style={{ textAlign: 'center' }}>
              <img src={CheckboxFilledInclined} alt="success" />
              <p
                style={{ fontSize: '24px', fontWeight: 600, marginTop: '16px' }}
              >
                {t('profileScreen.subscriptionCancelled')}
              </p>
              <p style={{ marginBottom: '20px' }}>
                {t('profileScreen.accessEnd')}{' '}
                <span style={{ fontWeight: 700 }}>{closestDate}</span>
              </p>
              <div style={{ textAlign: 'center' }}>
                <div
                  onClick={hideModal}
                  className="button-black"
                  style={{
                    display: 'inline-block',
                  }}
                >
                  {t('common.gotIt')}
                </div>
              </div>
            </div>
          )}
          {showPurchaseSuccess && (
            <div style={{ textAlign: 'center' }}>
              <img src={CheckboxFilledInclined} alt="success" />
              <p
                style={{ fontSize: '24px', fontWeight: 600, marginTop: '16px' }}
              >
                {t('profileScreen.upgradeSuccess')}
              </p>
              <p style={{ marginBottom: '20px' }}>
                {t('profileScreen.newPlan')}:{' '}
                <span style={{ fontWeight: 700 }}>
                  {t('profileScreen.access')}
                </span>
              </p>
              <div style={{ textAlign: 'center' }}>
                <div
                  onClick={hideModal}
                  className="button-black"
                  style={{
                    display: 'inline-block',
                  }}
                >
                  {t('common.gotIt')}
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
