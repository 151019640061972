import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ImageFromStorage from './ImageFromStorage';
import DeleteModal from './DeleteModal';
import EditAnswer from './EditAnswer';
import { format } from 'date-fns';
import { LuPencil } from 'react-icons/lu';
import { CgTrash } from 'react-icons/cg';
import AddPhotos from './AddPhotos';
import AddAnswer from './AddAnswer';
import { SKIPPED_ANSWER_VALUE } from '../constants/common';
import { useTranslation } from 'react-i18next';

const AnswerInfoModal = ({ visible, data, onHide, onAnswer }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [editAnswer, setEditAnswer] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={visible}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ border: 'none' }} onHide={onHide} />
        <Modal.Body>
          <p style={{ fontSize: 18, fontWeight: 700, marginBottom: 6 }}>
            {data?.question}
          </p>
          <p style={{ fontSize: 14 }}>{data?.context}</p>
          {data?.answers?.length ? (
            data?.answers
              ?.sort((a, b) => a?.timestamp - b?.timestamp)
              ?.map((answer, idx) => (
                <div
                  key={answer?.timestamp}
                  style={{
                    borderRadius: 12,
                    marginTop: idx !== 0 ? 12 : 0,
                    backgroundColor: 'white',
                    border: 'solid',
                    borderWidth: 1,
                    borderColor: '#8D98A9',
                    display: 'flex',
                    flexDirection: 'row',
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 12,
                    paddingBottom: 12,
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <Row className="mb-1">
                      <Col>
                        <span style={{ fontSize: 10, fontWeight: 700 }}>
                          {format(answer?.timestamp * 1000, 'MMM dd, yyyy')}
                        </span>
                      </Col>
                      <Col>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <div
                            style={{
                              width: 32,
                              height: 32,
                              borderRadius: 18,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderColor: 'grey',
                              borderWidth: 1,
                              borderStyle: 'solid',
                              marginRight: 6,
                              backgroundColor:
                                editAnswer?.id === answer?.id
                                  ? 'black'
                                  : 'transparent',
                            }}
                            onClick={() => {
                              setEditAnswer({
                                ...answer,
                                question: data?.question,
                                context: data?.context,
                              });
                            }}
                          >
                            <LuPencil
                              color={
                                editAnswer?.id === answer?.id
                                  ? 'white'
                                  : 'black'
                              }
                            />
                          </div>
                          <div
                            style={{
                              width: 32,
                              height: 32,
                              borderRadius: 18,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              backgroundColor: '#EB4444',
                            }}
                            onClick={() => setDeleteModal(answer?.id)}
                          >
                            <CgTrash color="white" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div
                      className="divider"
                      style={{ marginTop: 10, marginBottom: 10 }}
                    ></div>
                    <Row>
                      <Col>
                        {editAnswer?.id === answer?.id ? (
                          <EditAnswer
                            data={editAnswer}
                            onHide={() => setEditAnswer(false)}
                          />
                        ) : (
                          <span>
                            {answer?.['stripped-text'] === SKIPPED_ANSWER_VALUE
                              ? t('dashboardScreen.skipped')
                              : answer?.['stripped-text']}
                          </span>
                        )}
                      </Col>
                    </Row>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      {answer?.photos?.map(
                        (photo, idx) =>
                          photo && (
                            <ImageFromStorage
                              key={`photo_${idx}_of_question_${answer?.id}`}
                              link={photo}
                              height={100}
                              width={100}
                              objectFit="cover"
                              style={{ marginRight: 12 }}
                            />
                          )
                      )}
                    </div>
                  </div>
                  <Col
                    xs="auto"
                    className="d-flex"
                    style={{
                      justifyContent: 'space-around',
                      alignItems: 'flex-start',
                      paddingTop: 12,
                    }}
                  ></Col>
                </div>
              ))
          ) : (
            <AddAnswer data={data} onAnswer={onAnswer} />
          )}
          <AddPhotos questionId={data?.questionId} style={{ marginTop: 20 }} />
        </Modal.Body>
      </Modal>
      <DeleteModal
        deleteId={deleteModal}
        onHide={() => setDeleteModal(false)}
      />
    </>
  );
};

export default AnswerInfoModal;
