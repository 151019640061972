import { useState } from 'react';
import {
  introductionPrompt,
  chapter1Prompt,
  chapter2part1Prompt,
  chapter2part2Prompt,
  chapter2part3Prompt,
  chapter2part4Prompt,
  chapter3part1Prompt,
  chapter3part2Prompt,
  chapter3part3Prompt,
  chapter4part1Prompt,
  chapter4part2Prompt,
  chapter4part3Prompt,
  chapter5part1Prompt,
  chapter5part2Prompt,
  chapter6part1Prompt,
  chapter6part2Prompt,
  conclusionPrompt,
} from './prompts';
import { groupObjectsByProperty } from './helpers';
import { getSystemMessage } from './chatGPT';

const wrapInParagraphTags = (text) => {
  const pTagRegex = /^<p>.*<\/p>$/i;

  if (pTagRegex.test(text)) return text;

  return `<p>${text}</p>`;
};

const fetchChatGPT = (prompt, answers, language, cb) => {
  const systemMessage = getSystemMessage(language);
  const dataToSend = {
    model: 'gpt-3.5-turbo',
    messages: [systemMessage, { role: 'user', content: prompt(answers) }],
    temperature: 0.7,
  };

  return fetch('https://api.openai.com/v1/chat/completions', {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + process.env.REACT_APP_OPENAI_SECRET,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataToSend),
  })
    .then((data) => data.json())
    .then((finalData) => {
      cb(finalData.choices[0].message.content);
    });
};

export const fetchChatGPTSimple = (
  prompt,
  model = 'gpt-3.5-turbo',
  language
) => {
  const systemMessage = getSystemMessage(language);
  const dataToSend = {
    model,
    messages: [systemMessage, { role: 'user', content: prompt }],
    temperature: 0.3,
  };

  return fetch('https://api.openai.com/v1/chat/completions', {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + process.env.REACT_APP_OPENAI_SECRET,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataToSend),
  })
    .then((data) => data.json())
    .then((finalData) =>
      wrapInParagraphTags(finalData.choices[0].message.content)
    );
};

function useGenerateBookStory(userAnswers, onEnd) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});

  const simplifiedAnswers = userAnswers
    ?.map((a) => ({
      questionId: Number(a?.questionId),
      answer: a?.['stripped-text'],
      photos: a?.photos,
    }))
    .sort((a, b) => a?.questionId - b?.questionId);

  const groupedByQuestionId =
    groupObjectsByProperty(simplifiedAnswers, 'questionId') || {};

  const textAnswers = Object.values(groupedByQuestionId)?.map((answers) => {
    let singleAnswer = '';
    answers?.forEach((a) => {
      if (a?.answer && a?.answer !== null) {
        singleAnswer += a?.answer;
      }
    });
    return singleAnswer;
  });

  const generate = async () => {
    try {
      setLoading(true);
      const book = {};

      await fetchChatGPT(
        introductionPrompt,
        textAnswers,
        (value) => (book.introduction = value)
      );
      await fetchChatGPT(
        chapter1Prompt,
        textAnswers,
        (value) => (book.chapter1 = value)
      );
      await fetchChatGPT(
        chapter2part1Prompt,
        textAnswers,
        (value) => (book.chapter2part1 = value)
      );
      await fetchChatGPT(
        chapter2part2Prompt,
        textAnswers,
        (value) => (book.chapter2part2 = value)
      );
      await fetchChatGPT(
        chapter2part3Prompt,
        textAnswers,
        (value) => (book.chapter2part3 = value)
      );
      await fetchChatGPT(
        chapter2part4Prompt,
        textAnswers,
        (value) => (book.chapter2part4 = value)
      );
      await fetchChatGPT(
        chapter3part1Prompt,
        textAnswers,
        (value) => (book.chapter3part1 = value)
      );
      await fetchChatGPT(
        chapter3part2Prompt,
        textAnswers,
        (value) => (book.chapter3part2 = value)
      );
      await fetchChatGPT(
        chapter3part3Prompt,
        textAnswers,
        (value) => (book.chapter3part3 = value)
      );
      await fetchChatGPT(
        chapter4part1Prompt,
        textAnswers,
        (value) => (book.chapter4part1 = value)
      );
      await fetchChatGPT(
        chapter4part2Prompt,
        textAnswers,
        (value) => (book.chapter4part2 = value)
      );
      await fetchChatGPT(
        chapter4part3Prompt,
        textAnswers,
        (value) => (book.chapter4part3v = value)
      );
      await fetchChatGPT(
        chapter5part1Prompt,
        textAnswers,
        (value) => (book.chapter5part1 = value)
      );
      await fetchChatGPT(
        chapter5part2Prompt,
        textAnswers,
        (value) => (book.chapter5part2 = value)
      );
      await fetchChatGPT(
        chapter6part1Prompt,
        textAnswers,
        (value) => (book.chapter6part1 = value)
      );
      await fetchChatGPT(
        chapter6part2Prompt,
        textAnswers,
        (value) => (book.chapter6part2 = value)
      );
      await fetchChatGPT(
        conclusionPrompt,
        textAnswers,
        (value) => (book.conclusion = value)
      );

      setData(book);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
      onEnd();
    }
  };

  return { generate, loading, error, data };
}
