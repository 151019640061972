import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/firebase';
import { useTranslation } from 'react-i18next';

function LoginForm() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [signInWithEmailAndPassword, user, loading, error] =
    useSignInWithEmailAndPassword(auth);
  const { t } = useTranslation();

  useEffect(() => {
    if (error) {
      setErrorMessage(t('loginScreen.loginErrorMessage'));
    }
  }, [error]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrorMessage(null);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(formData?.email, formData?.password);
  };

  return (
    <Form onSubmit={handleSubmit} style={{ textAlign: 'left' }}>
      <Form.Group className="mb-2" controlId="formBasicEmail">
        <Form.Label>{t('common.email')}</Form.Label>
        <Form.Control
          value={formData.email}
          onChange={handleInputChange}
          name="email"
          type="email"
        />
      </Form.Group>
      <Form.Group className="mb-4" controlId="formBasicPassword">
        <Form.Label className="align-left">{t('common.password')}</Form.Label>
        <Form.Control
          value={formData.password}
          onChange={handleInputChange}
          name="password"
          type="password"
        />
      </Form.Group>
      <span className="text-danger">{errorMessage}</span>
      <div class="d-grid gap-2" style={{ marginTop: 40 }}>
        <Button variant="dark" type="submit" size="lg">
          {t('loginScreen.login')}
        </Button>
      </div>
    </Form>
  );
}

export default LoginForm;
