import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QuestionTemplate from './QuestionTemplate';
import AnswerInfoModal from './AnswerInfoModal';
import InfoModal from './InfoModal';

const AnswerItem = ({ data, current, closeModalAfterSaving }) => {
  const [answerInfoModal, setAnswerInfoModal] = useState(false);
  const [answerSuccessModal, setAnswerSuccessModal] = useState(false);

  const { t } = useTranslation();

  const answered = data?.answers?.length > 0;
  const answer = data?.answers?.sort(
    (a, b) => a?.timestamp - b?.timestamp
  )?.[0]?.['stripped-text'];

  const getDisabled = () => {
    if (current) {
      return false;
    }
    if (data?.available) {
      return false;
    }
    if (data?.answers?.length > 0) {
      return false;
    }

    return true;
  };

  return (
    <div>
      <QuestionTemplate
        id={data?.questionId}
        question={data?.question}
        context={answered ? answer : data?.context}
        photos={data?.photos}
        answered={answered}
        disabled={getDisabled()}
        current={current}
        onClick={() => setAnswerInfoModal(true)}
      />
      <AnswerInfoModal
        visible={answerInfoModal}
        data={data}
        onAnswer={() => {
          if (closeModalAfterSaving) {
            setAnswerInfoModal(false);
          }
        }}
        onHide={() => setAnswerInfoModal(false)}
      />
      <InfoModal
        visible={answerSuccessModal}
        onHide={() => setAnswerSuccessModal(false)}
        title={t('dashboardScreen.answerSuccessfullyRecorded')}
        text={t('dashboardScreen.nicelyDone')}
      />
    </div>
  );
};

export default AnswerItem;
