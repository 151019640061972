export const generateUniqueId = () => {
  const timestamp = Date.now();
  const randomNum = Math.floor(Math.random() * 1000);

  return (timestamp + randomNum).toString(36);
};

export const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const options = { day: '2-digit', month: 'short', year: 'numeric' };

  return date.toLocaleDateString('en-GB', options);
};
