import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { storage } from '../firebase/firebase';
import { ref, uploadBytes } from 'firebase/storage';
import ErrorToast from './ErrorToast';

const SendToPrintModal = ({ visible, onClose, userData }) => {
  const [coverFile, setCoverFile] = useState('');
  const [pdfFile, setPdfFile] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [error, setError] = useState(null);

  const sendPrintJob = async () => {
    try {
      setError(null);
      // Upload cover
      const coverPath = `covers/${coverFile?.name?.replace(
        /[^a-zA-Z0-9]+/g,
        '_'
      )}`;
      const coverRef = ref(storage, coverPath);
      const uploadedCoverFile = await uploadBytes(coverRef, coverFile);
      const coverFilePath = `https://firebasestorage.googleapis.com/v0/b/memowrite-efa1b.appspot.com/o/covers%2F${uploadedCoverFile?.metadata?.name}?alt=media`;

      // Upload pdf
      const photoPath = `books/${pdfFile?.name?.replace(
        /[^a-zA-Z0-9]+/g,
        '_'
      )}`;
      const pdfRef = ref(storage, photoPath);
      const uploadedPdfFile = await uploadBytes(pdfRef, pdfFile);
      const pdfFilePath = `https://firebasestorage.googleapis.com/v0/b/memowrite-efa1b.appspot.com/o/books%2F${uploadedPdfFile?.metadata?.name}?alt=media`;

      const url =
        'https://memowrite.app.n8n.cloud/webhook/ebec2544-ed5a-4526-a5a9-c02b6b981972';
      const dataToSend = {
        contact_email: userData.email,
        external_id: `${userData.email}_${Date.now()}`,
        line_items: [
          {
            printable_normalization: {
              cover: {
                source_url: coverFilePath,
              },
              interior: {
                source_url: pdfFilePath,
              },
              pod_package_id: '0583X0827FCSTDCW060UW444MXX',
            },
            quantity,
            title: 'My Life Story',
          },
        ],
        production_delay: 1440,
        shipping_address: {
          city: userData?.shipping_address?.city,
          country_code: userData?.shipping_address?.country_code,
          name: `${userData?.shipping_address?.first_name} ${userData?.shipping_address?.last_name}`,
          phone_number: userData?.shipping_address?.phone,
          postcode: userData?.shipping_address?.zip_code,
          state_code: userData?.shipping_address?.state_code,
          email: userData?.shipping_address?.email,
          street1: userData?.shipping_address?.street_address,
        },
        shipping_level: 'MAIL',
      };

      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(dataToSend),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (data) {
        onClose();
      }
    } catch (error) {
      setError('Error sending print job');
      console.error('Error sending print job: ', error);
    }
  };

  return (
    <>
      <Modal show={visible} onHide={onClose} centered>
        <Modal.Header closeButton style={{ border: 'none' }}>
          <Modal.Title>Order information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Cover pdf</Form.Label>
          <InputGroup>
            <Form.Control
              type="file"
              onChange={(e) => setCoverFile(e.target.files[0])}
            />
          </InputGroup>
          <Form.Label className="mt-4">Book pdf</Form.Label>
          <InputGroup>
            <Form.Control
              type="file"
              onChange={(e) => setPdfFile(e.target.files[0])}
            />
          </InputGroup>
          <Form.Label className="mt-4">Quantity</Form.Label>
          <InputGroup>
            <Form.Control
              aria-label="Default"
              aria-describedby="inputGroup-sizing-default"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              type="number"
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!pdfFile || !coverFile}
            variant="primary"
            className="button-update"
            onClick={sendPrintJob}
          >
            Submit
          </Button>
          <Button
            variant="outline-dark"
            className="button-update"
            onClick={onClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {error && <ErrorToast errorMessage={error} />}
    </>
  );
};

export default SendToPrintModal;
