import Button from 'react-bootstrap/Button';
import CustomCover from './CustomCover';
import { auth } from '../firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useTranslation } from 'react-i18next';
import { useUserData } from '../hooks/useUserData';

const Upsell = ({ onPress }) => {
  const { t } = useTranslation();
  const [user] = useAuthState(auth);
  const { userData } = useUserData();

  const handleBuy = () => {
    window.open(
      `https://shop.getmemowrite.com/cart/40411692761182:1,40412280979550:1?checkout[email]=${user?.email}`,
      '_blank',
      'noopener,noreferrer'
    );
    onPress();
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      className="text-center"
    >
      <h2>{t('dashboardScreen.lastChance')}</h2>
      <p>{t('dashboardScreen.ifYouChoose')}</p>
      {userData?.cover && (
        <div style={{ position: 'relative' }}>
          <CustomCover
            cover={userData?.cover}
            width={320}
            coverPhoto={userData?.cover_photo}
            withPhoto={userData?.cover < 7}
          />
          <div style={{ position: 'absolute', top: -15, left: 25 }}>
            <CustomCover
              cover={userData?.cover}
              width={320}
              coverPhoto={userData?.cover_photo}
              withPhoto={userData?.cover < 7}
              name={userData?.name}
            />
          </div>
        </div>
      )}
      <Button variant="dark" className="button-update mt-2" onClick={handleBuy}>
        {t('dashboardScreen.getExtraCopies')}{' '}
        <span style={{ color: '#ff5759', textDecoration: 'line-through' }}>
          $99
        </span>{' '}
        $49.99 {t('dashboardScreen.each')}
      </Button>
      <Button
        variant="link"
        style={{ color: 'black', fontSize: 14 }}
        className="mt-2"
        onClick={onPress}
      >
        {t('dashboardScreen.iDontNeedCopies')}
      </Button>
    </div>
  );
};

export default Upsell;
