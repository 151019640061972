import Modal from 'react-bootstrap/Modal';
import CheckboxFilledInclined from '../assets/icons/checkbox_filled_inclined.svg';
import { useTranslation } from 'react-i18next';

export const ChangePasswordSuccessModal = ({ visible, onHide }) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={visible}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body style={{ padding: '40px' }}>
        <div style={{ textAlign: 'center' }}>
          <img src={CheckboxFilledInclined} alt="success" />
          <p style={{ fontSize: '24px', fontWeight: 600, marginTop: '16px' }}>
            {t('profileScreen.successTitle')}
          </p>
          <p style={{ color: '#00000099', marginBottom: '20px' }}>
            {t('profileScreen.successMessage')}
          </p>
          <div style={{ textAlign: 'center', marginTop: '40px' }}>
            <div
              onClick={onHide}
              className="button-black"
              style={{
                display: 'inline-block',
              }}
            >
              {t('common.gotIt')}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
