import React, { useEffect } from 'react';

const MouseflowScript = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window._mfq = window._mfq || [];
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.defer = true;
      script.src =
        '//cdn.mouseflow.com/projects/6cbd479f-2d4f-43fe-b57a-40c8a8ad5122.js';
      document.head.appendChild(script);
    }
  }, []);

  return null;
};

export default MouseflowScript;
