import { SystemMessageContent } from '../constants/chatGPT';

export function getSystemMessage(language) {
  const content = SystemMessageContent[language] || SystemMessageContent.en;

  return {
    role: 'system',
    content,
  };
}
